<template>
  <div class="login-main">
    <nav-bar v-on:sendDefaultAddress="getAddress" :checkpage="page"></nav-bar>
    <section class="review-order-start box-flex">
      <div class="container review-main text-capitalize position-relative review-container-nw box-flex__wrapper">
        <div class="row">
          <div class="col-md-12 p-0">
            <div v-if="cartItemCount > 0">
              <div class="center-card" v-if="isTemplateShow">
                <div class="center-card-box | p-3 top-sticky border-bottom">
                  <div class="resturant-details | d-flex align-items-center">
                    <div class="rst-nme-add">
                      <h4 class="mt-0">
                        {{ $t("Your Cart")
                        }}<span class="total-items">({{cart.reduce((a, c) => a + c.quantity, 0)}}
                          {{
                            cart.reduce((a, c) => a + c.quantity, 0) > 1
                              ? $t("Items")
                              : $t("Item")
                          }})</span>
                      </h4>
                      <p class="frm-txt">
                        {{ $t("From") }} :
                        <a href="javascript:void(0)" v-if="!restaurantData.restaurant_id"><skeleton-loader-vue
                            type="string" width="50%" height="20px" /></a>
                        <router-link v-else :to="{
                          name: 'restaurant',
                          params: { restaurant_id: encodeBase64(restaurantData.restaurant_id) },
                        }" class="resst-lnk">{{ this.restaurantData.name }}</router-link>
                      </p>
                    </div>
                    <div class="clen-crt-btn ms-auto">
                      <p class="crt-clnt-txt" @click="cleanCart()">
                        {{ $t("Clear Cart") }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="order-items-main plr15 review-cart-item">
                  <div class="order-item d-flex align-items-center justify-content-between mb-0"
                    v-for="(item, index) in this.cart" :key="index">
                    <div class="item-heading-main">
                      <!-- <div class="crt-img crt-img-nw" v-if="item.image && item.item_type != '2'" -->

                      <div class="veg nveg mb-4" v-if="item.item_type == '0' && $auth.setting.is_cart_image == '0'">
                        <span></span>
                      </div>
                      <div class="non-veg nveg mb-4" v-if="item.item_type == '1' && $auth.setting.is_cart_image == '0'">
                        <span></span>
                      </div>

                      <div class="crt-img crt-img-nw" data-bs-toggle="modal" data-bs-target="#imageModal"
                        v-if="item.image || item.item_type == '0' || item.item_type == '1'"
                        :class="item.image == '' || item.image == null ? 'noimg' : ''">
                        <!-- <img v-if="item.image" :src="item.image" @error="$event.target.src = noImge" /> -->

                        <img v-if="item.image && $auth.setting.is_cart_image == '1'" v-lazy="item.image"
                          @error="$event.target.src = noImge" @click="openImagePreview(item.image, item.name)" />

                        <div class="veg veg-non-nw" v-if="item.item_type == '0' && $auth.setting.is_cart_image == '1'">
                          <span></span>
                        </div>
                        <div class="non-veg veg-non-nw"
                          v-if="item.item_type == '1' && $auth.setting.is_cart_image == '1'">
                          <span></span>
                        </div>
                        <!-- <img src="@/assets/images/non_veg.png" /> -->
                      </div>
                      <div class="item-heading item-heading-nw">
                        <h6 class="item-c-heading">{{ item.name }} :</h6>
                        <p class="item-c-price m-0">
                          <span class="item-price" v-if="item.price != '0'">
                            <span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>
                            {{ $formatPrice(item.price) }}</span>
                          <!-- {{ item.price.toFixed(2) }}</span> -->
                        </p>
                        <div class="customized customized-primary d-inline-block" v-if="item.selected_type.length > 0">
                          <p class="customized-lable text-start">customized</p>
                          <div class="selected_type flex-column">
                            <div class="selected-main">
                              <p v-for="(subitem, i) in item.selected_type" :key="i">
                                {{ subitem }}
                                <span v-if="i != Object.keys(item.selected_type).length - 1">,</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-column align-items-center text-end justify-content-center mobiitem-nw">
                      <div class="number d-flex align-items-start counter-nw">
                        <span class="minus d-flex justify-content-center align-items-center"
                          @click="minusItem(item, index)">-</span>

                        <input type="text" :value="item.quantity" readonly />
                        <span class="plus d-flex justify-content-center align-items-center"
                          @click="plusItem(item, index)">+</span>
                      </div>
                      <div class="final-item-price final-item-price-nw">
                        <p class="mb-0">
                          <span id="currency" v-html="$auth.setting && $auth.setting.currency"
                            class="currency"></span>{{ $formatPrice(item.price * item.quantity) }}
                          <!-- parseFloat(getTotalItemPrice(item)).toFixed(2) }} -->
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="plr15 review-section">
                  <div class="review-boxs mt-4">
                    <h6 class="mb-2">{{ $t("special instructions") }}</h6>
                    <input class="form-control mb-0 h50" type="search" :placeholder="instructionPlaceholder"
                      aria-label="instructions" v-model="instructions" />
                  </div>
                  <!-- <div class="review-boxs mt-4 promocode-sec">
                    <h6 class="mb-2">{{ $t("promo code") }}</h6>
                    <a href="javascript:void(0)" @click="promocodeModal" class="lnk offer-btn">{{ $t("View Offers") }}</a>
                    <input class="form-control mb-0 h50" type="text" id="promo" :placeholder="$t('Enter Promo code')"
                      aria-label="promocode" v-model="promocode" ref="promocode"
                      :disabled="isPromocodeApply && !promocode ? true : false" />
                    <a href="javascript:void(0)" @click="applyPromocode(promocode)" class="lnk"
                      v-if="!isPromocodeApply && !promocode">{{ $t("Apply") }}</a>
                    <a href="javascript:void(0)" @click="removePromocode()" class="lnk" v-if="isPromocodeApply">{{
                      $t("Remove") }}</a>
                  </div> -->

                  <div class="review-boxs mt-4 promocode-sec">
                    <h6 class="mb-2">{{ $t("promo code") }}</h6>
                    <a href="javascript:void(0)" @click="promocodeModal" class="lnk offer-btn">{{ $t("View Offers")
                    }}</a>
                    <input class="form-control mb-0 h50" type="text" :placeholder="$t('Enter Promo code')"
                      aria-label="promocode" v-model="promocode" ref="promocode"
                      :disabled="isPromocodeApply ? true : false" />
                    <a href="javascript:void(0)" @click="applyPromocode(promocode)" class="lnk"
                      v-if="!isPromocodeApply || !promocode">{{ $t("Apply") }}</a>
                    <a href="javascript:void(0)" @click="removePromocode()" class="lnk"
                      v-if="isPromocodeApply && promocode">{{ $t("Remove") }}</a>
                  </div>

                  <p v-if="!isPromocodeApply && promoSuggetion" class="promoerr">
                    {{ promoSuggetion }}
                  </p>
                  <p v-if="promoError" class="promoerr">{{ promoError }}</p>
                  <div class="review-boxs mt-4 delivery-time">
                    <div class="d-flex justify-content-between align-items-center">
                      <h6 class="mb-0">
                        {{
                          isPickupDelivery == 1 || isPickupDelivery == -1
                            ? $t("Delivery Time")
                            : `${$auth.setting.terminology[0].takeaway_text} ${$t(
                              "Time"
                            )}`
                        }}
                      </h6>
                      <delivery-time v-if="restaurantId > 0" ref="DeliveryTime" :isDelivery="isPickupDelivery"
                        :restaurantId="restaurantId" :restaurantData="restaurantData"
                        v-on:setOrderTime="onSetOrderTime"></delivery-time>
                    </div>
                    <p class="mb-0 mt-2">
                      {{
                        scheduleDateTime.time &&
                          scheduleDateTime.time != "" &&
                          scheduleDateTime.time != "asap"
                          ? scheduleDateTime.displayText
                          : isAsapEnable
                            ? restaurantData && (!isRestaurantClosed || !isSameDayDelivery)
                              ? $t("Select Time Slot")
                              : $t("As soon As possible")
                            : isPickupDelivery == 1 || isPickupDelivery == -1
                              ? $t("Select delivery time")
                              : $t("Select takeaway time")
                      }}
                    </p>
                  </div>
                  <!-- <div class="review-boxs mt-4"
                    v-if="$auth.setting && $auth.setting.driver_tip && this.$auth.setting.driver_tip.length > 0 && (isPickupDelivery == 1)">
                    <h6 class="mb-2">{{ $t('give tip to driver', { msg: $auth.setting.driver_name }) }}</h6>
                    <div class="tip-drivers">
                      <div class="input-group d-inline-flex w-auto"
                        v-for="tip in this.$auth.setting.driver_tip">
                        <input type="radio" name="driver_tip" class="visually-hidden" v-model="driver_tip"
                          :id="'tip_' + tip.id" :value="tip.value" @click="getTipValue(tip.value, 'driver_tip', tip.id)">
                        <label :for="'tip_' + tip.id" class="cust-radio-btn"><span
                            v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{ tip.value
                            }}</label>
                      </div>
                      <div class="input-group w-auto">
                        <input type="radio" name="driver_tip" id="tip_other" class="visually-hidden"
                          :value="other_driver_tip" @click="getTipValue(other_driver_tip, 'other_driver_tip', '')">
                        <label for="tip_other" class="cust-radio-btn" @click="tipModal"
                          v-if="other_driver_tip == 0">Other</label>
                        <label for="tip_other" class="cust-radio-btn" v-if="other_driver_tip > 0"><span
                            v-html="$auth.setting.currency" class="currency"></span> {{ other_driver_tip }} <i
                            class="fas fa-pen ms-2" @click="tipModal"></i></label>
                      </div>
                    </div>
                  </div> -->

                  <div class="review-boxs mt-4" v-if="getDeliveryZoneSettings.driver_tip && isPickupDelivery == 1">
                    <h6 class="mb-2">
                      {{ $t("give tip to driver", { msg: $auth.setting.driver_name }) }}
                    </h6>
                    <div class="tip-drivers">
                      <div v-for="tip in getDeliveryZoneSettings.driver_tip" :key="tip.id"
                        v-if="tip.value.length !== 0">
                        <input type="radio" name="driver_tip" class="visually-hidden" v-model="driver_tip"
                          :id="'tip_' + tip.id" :value="tip.value"
                          @click="getTipValue(tip.value, 'driver_tip', tip.id)" />
                        <label :for="'tip_' + tip.id" class="cust-radio-btn"><span
                            v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{ tip.value
                            }}</label>
                      </div>
                      <div class="input-group w-auto">
                        <input type="radio" name="driver_tip" id="tip_other" class="visually-hidden"
                          :checked="other_driver_tip > 0" :value="other_driver_tip"
                          @click="getTipValue(other_driver_tip, 'other_driver_tip', '')" />
                        <label for="tip_other" class="cust-radio-btn" @click="tipModal"
                          v-if="other_driver_tip == 0">Other</label>
                        <label for="tip_other" class="cust-radio-btn" v-if="other_driver_tip > 0"><span
                            v-html="$auth.setting.currency" class="currency"></span>
                          {{ other_driver_tip }}
                          <i class="fas fa-pen ms-2" @click="tipModal"></i></label>
                      </div>
                    </div>
                  </div>



                  <!-- {{ porter delivery charges getting }} -->
                  <!-- <button class="btn" @click="portalModalOpen()">{{ $t("Porter delivery Charges") }} </button> -->
                  <!-- <div class="review-boxs mt-4" v-if="isPickupDelivery == 1">
                    <h6 class="mb-2">
                      {{ $t("Porter delivery Charges") }}
                    </h6>
                    <div class="tip-drivers">
                      <div v-for="(charge, index) in porterCharges" :key="index">
                        <input type="radio" class="visually-hidden" v-model="seletectedPorter" :id="'tip_' + index"
                          :value="charge.minor_amount" @click="getPorterValue(charge.minor_amount, index)" />
                        <label :for="'tip_' + index" class="cust-radio-btn"><span
                            v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{
                              charge.minor_amount
                            }}</label>
                      </div>
                    </div>
                  </div> -->

                  <div class="review-boxs mt-4"
                    v-if="this.isPickupDelivery == 1 && this.$auth.setting.is_active_porter == 'Yes' && this.$auth.setting.porter_delivery_charges == 'Yes' && this.showPorterChrages == true">
                    <h6 class="mb-3 text-start">{{ $t("Select Your Delivery Option") }}</h6>
                    <div class="options-grid">
                      <div v-for="(charge, index) in porterCharges" :key="index" class="option-card"
                        :class="['option-card', 'card', { 'active-border': activePorterIndex === index }]"
                        @click="getPorterValue(charge.minor_amount, index)">
                        <input type="radio" class="visually-hidden" v-model="seletectedPorter" :id="'tip_' + index"
                          :value="charge.minor_amount" />
                        <label :for="'tip_' + index"
                          :class="['cust-porter-btn', { 'active-border': activePorterIndex === index }]">
                          <div class="card-body text-center vehicle-name">
                            <label class="card-title">{{ charge.vehicle_name }}</label>
                            <p class="card-text">
                              <span v-html="$auth.setting && $auth.setting.currency"></span>{{
                                (charge.minor_amount / 100).toFixed(2)
                              }}
                            </p>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>





                  <div class="review-boxs mt-4" v-if="deliveryInstruction != ''">
                    <h6 class="mb-2">
                      {{
                        $t("Delivery Instruction ", { msg: $auth.setting.driver_name })
                      }}
                    </h6>
                    <div class="tip-drivers">
                      <div v-for="instruction in deliveryInstruction" :key="instruction.id">
                        <input type="checkbox" name="delivery_instruction" class="visually-hidden"
                          :id="'delivery_instruction' + instruction.id" :value="instruction.value"
                          v-model="isInstruction[instruction.id]" @click="
                            getDeliveryInstruction(
                              instruction.value,
                              'delivery_instruction',
                              instruction.id
                            )
                            " />
                        <label :for="'delivery_instruction' + instruction.id" class="cust-radio-btn">
                          {{ instruction.value }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="billing-data custom-checkbox" v-if="
                  $auth.setting &&
                  $auth.setting.contact_less_delivery &&
                  (isPickupDelivery == 1 || isPickupDelivery == -1) &&
                  $auth.setting.is_pickup_enable == 'true' &&
                  $auth.setting.contact_less_delivery == 1
                ">
                  <div class="d-flex justify-content-between align-items-center form-group">
                    <h6>{{ $t("Opt in for No-contact Delivery") }}</h6>
                    <input class="form-group" id="cntctdelivery" type="checkbox" true-value="1" false-value="0"
                      v-model="contactDelivery" />
                    <label class="form-check-label" for="cntctdelivery"></label>
                  </div>
                  <p class="pcolor">
                    {{
                      this.$auth.setting.terminology &&
                      $auth.setting.terminology[0].contact_less_delivery_text
                    }}
                  </p>
                </div>
                <div class="billing-data custom-checkbox custom-checkbox-nw p-3"
                  v-if="$auth.setting && $auth.setting.is_upload_order_image == 'Yes'">
                  <div class="d-flex justify-content-between align-items-center form-group">
                    <h6>{{ $auth.setting.image_upload_text }}</h6>
                  </div>
                  <div class="fileupload-main d-flex align-items-start">
                    <label class="filelabel"><i class="fa fa-plus"></i>
                      <input multiple accept="image/png,image/jpeg,image/jpg" @change="uploadImage" class="FileUpload1"
                        id="FileInput" type="file" /></label>
                    <div v-if="images.length > 0" class="uploaded-img-main">
                      <div v-for="(img, key) in images" :key="key" class="prvie-img uploaded-img">
                        <p class="remove-flg" @click="removeUploadImage(img.id)">X</p>
                        <img v-lazy="img.image" class="dis-img" @click="openImg(img)" />
                      </div>
                      <div class="text-center upload-loader" v-if="loading">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="billing-data" class="billing-data p-3">
                  <h6 class="mb-3">{{ $t("Billing Details") }}</h6>
                  <ul>
                    <li class="d-flex justify-content-between">
                      <span v-if="itemTexDisplay() > '0.00'">
                        {{ $t("Item Total") }}
                        <i v-b-tooltip.hover.right.nofade.html.v-secondary="'Included item ' +
                          ($auth.setting.terminology &&
                            $auth.setting.terminology[0].tax_name !== ''
                            ? $auth.setting.terminology[0].tax_name
                            : '') +
                          ' ' +
                          $auth.setting.currency +
                          ' ' +
                          itemTexDisplay()
                          " class="fa fa-info-circle" aria-hidden="true"></i></span>
                      <span v-else>{{ $t("Item Total") }}</span>
                      <span><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{
                        getTotalItemAmount() }}</span>
                    </li>

                    <!-- <li class="d-flex justify-content-between" v-if="packagingCharge > 0">
                      <span>{{ $t("Packaging Charge") }}</span>
                      <span><span v-html="$auth.setting.currency" class="currency"></span>{{
                        parseFloat(packagingCharge).toFixed(2) }}</span>
                    </li>

                    <li class="d-flex justify-content-between" v-if="this.$auth.setting &&
                      (this.$auth.setting.included_tax == 0 || this.$auth.setting.included_tax == 'Yes')">
                      {{ this.$auth.setting && this.$auth.setting.terminology &&
                        this.$auth.setting.terminology[0].tax_name }}({{ this.gstMaxtax.toFixed(2) }}%)
                      <span><span v-html="$auth.setting.currency" class="currency"></span>{{ gstTotalAmount() }}</span>
                    </li> -->

                    <li class="d-flex justify-content-between d-none"
                      v-if="deliveryCharge == 0.0 && isPickupDelivery == 1">
                      {{ $t("Delivery Charges") }}<span>{{ $t("Free") }}</span>
                    </li>

                    <li v-if="
                      promoCodeType == 5 &&
                      promocode &&
                      deliveryCharge > 0 &&
                      isPickupDelivery == 1
                    " class="d-flex justify-content-between">
                      {{ $t("Delivery Charges") }}
                      <div>
                        <span class="text-decoration-line-through">
                          <span v-html="$auth.setting.currency" class="currency"></span>{{ deliveryCharge }}</span>
                        <span class="text-success mx-1">{{ $t("Free") }}</span>
                      </div>
                    </li>

                    <li class="d-flex justify-content-between" v-else-if="deliveryCharge > 0 && isPickupDelivery == 1">
                      {{ $t("Delivery Charges") }}
                      <span>
                        <span v-html="$auth.setting.currency" class="currency"></span>{{ deliveryCharge }}
                      </span>
                    </li>

                    <li class="d-flex justify-content-between" v-if="others_fees > 0">
                      <span>
                        {{ $t("Taxes & Other Fees") }}
                        <i class="fa fa-info-circle" aria-hidden="true" @click="otherFeesModal()"></i></span>
                      <span><span v-html="$auth.setting.currency" class="currency"></span>{{ $formatPrice(others_fees)
                      }}</span>
                    </li>

                    <li class="d-flex justify-content-between" v-if="driver_tip > 0 && isPickupDelivery == 1">
                      {{ $t("Driver Tip")
                      }}<span><span v-html="$auth.setting.currency" class="currency"></span>{{ driver_tip }}</span>
                    </li>

                    <li class="d-flex justify-content-between" v-if="new_driver_tip > 0 && isPickupDelivery == 1">
                      {{ $t("Driver Tip")
                      }}<span><span v-html="$auth.setting.currency" class="currency"></span>{{ other_driver_tip
                      }}</span>
                    </li>

                    <!-- <li class="d-flex justify-content-between" v-if="restaurantData.restaurant_convince_charge > 0">
                      {{ $t("Convenience Charges") }}<span><span v-html="$auth.setting.currency"
                          class="currency"></span>{{ getConvenienceCharge() }}</span>
                    </li> -->
                    <li class="d-flex justify-content-between discount" v-if="promoDiscount && promoCodeType != 5">
                      {{ $t("Discount") }}
                      <span>- <span v-html="$auth.setting.currency" class="currency"></span>{{
                        parseFloat(promoDiscount).toFixed(2) }}</span>
                    </li>
                    <li class="d-flex justify-content-between promo-discount" v-if="promoCashback">
                      {{ $t("Cashback")
                      }}<span><span v-html="$auth.setting.currency" class="currency"></span>{{ promoCashback.toFixed(2)
                      }}</span>
                    </li>

                    <!-- <li class="d-flex justify-content-between bold" isPickupDelivery == 1>
                      {{ $t("To Pay") }}<span><span v-html="$auth.setting && $auth.setting.currency"
                          class="currency"></span>{{ getTotalAmount() }}</span>
                    </li>  -->
                    <li class="d-flex justify-content-between bold">
                      {{ $t("To Pay")
                      }}<span><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{
                        getTotalAmount() }}</span>
                    </li>
                  </ul>
                  <div class="svprc">
                    <p class="d-flex justify-content-between"
                      v-if="savedPrice() > 0 || promoDiscount > 0 || promoCashback > 0 || deliveryCharge > 0">
                      {{ $t("You Saved") }}
                      <span><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{
                        (savedPrice() + Number(promoDiscount) + promoCashback).toFixed(2)
                      }}</span>
                    </p>
                  </div>
                  <li v-if="isPickupDelivery == 1 || isPickupDelivery == -1">
                    <p class="pcolor">
                      {{
                        $auth.setting &&
                        $auth.setting.terminology &&
                        $auth.setting.terminology[0].cart_info_text
                      }}
                    </p>
                  </li>
                </div>
                <div class="delivery-address plr15">
                  <!-----------Radio Delivery and pickup --------------->
                  <div class="d-flex align-items-center justify-content-between mb-3">
                    <div class="delivery-pickup-main d-flex align-items-center mb-1" v-if="
                      (deliveryTypeSlots == '2' || deliveryTypeSlots == '') &&
                      $auth.setting &&
                      $auth.setting.is_pickup_enable == 'true' &&
                      checkPickupStatus == 'true'
                    ">
                      <div class="delivery-pickup-lnk me-2">
                        <div class="switch" id="switch">
                          <input name="radio" @click="getDelivery('1')" type="radio" value="1" id="delivery"
                            :checked="isPickupDelivery == 1" />
                          <label for="delivery">{{ $t("Delivery") }}</label>
                          <input name="radio" @click="getDelivery('0')" type="radio" value="0" id="pickup"
                            :checked="isPickupDelivery == 0" />
                          <label for="pickup" class="right">{{
                            $auth.setting.terminology[0].takeaway_text
                              ? $auth.setting.terminology[0].takeaway_text
                              : "Takeaway"
                          }}</label>
                          <span class="swtact" :class="this.rightClass || {
                            right: ['0'].includes(isPickupDelivery),
                          }
                            " aria-hidden="true"></span>
                        </div>
                      </div>
                    </div>
                    <!-----------Radio Delivery and pickup --------------->
                    <!-- <h6 class="mb-0">-->
                    <div class="delivery-nw-section" v-if="
                      isPickupDelivery == 1 ||
                      isPickupDelivery == -1 ||
                      $auth.setting.is_pickup_enable == 'false'
                    ">
                      <span v-if="primaryAddress && primaryAddress.adress_clarification">{{
                        $t("Delivery To " + primaryAddress.adress_clarification)
                        }}</span>
                      <div v-if="
                        Object.keys(primaryAddress).length == 0 ||
                        primaryAddress.deliveryAddress == ''
                      " class="addaddrs">
                        <router-link :to="{ name: 'add-address' }">{{
                          $t("Add New Address")
                          }}</router-link>
                      </div>
                      <div v-if="addressList.length > 0" class="chng-addrs">
                        <a href="javascript:void(0)" @click="deliveryModalOpen" class="lnk">{{ $t("Change Address")
                        }}</a>
                      </div>
                      <!-- </h6> -->
                      <p>{{ primaryAddress.deliveryAddress }}</p>
                    </div>
                    <!-- v-if="$auth.setting.is_pickup_enable == 'true' || isPickupDelivery == 0 || isPickupDelivery == -1" -->
                    <div v-else class="pckup-addr-cls">
                      <span class="pickup-txt">{{ $t("Pickup from") }}</span>
                      <div class="map-add-cls">
                        <a :href="'https://www.google.com/maps?saddr=' +
                          latitude +
                          ',' +
                          longitude +
                          '&daddr=' +
                          restaurantLat +
                          ',' +
                          restaurantLong
                          " target="_blank">></a>
                      </div>
                      <div class="pic-cls-con">
                        <p v-bind:title="restaurantData.location_address">
                          {{ this.restaurantData.location_address }}
                        </p>
                      </div>
                    </div>

                    <!-- <div v-if="isPickupDelivery == 0" class="pckup-addr-cls">
                      <span class="pickup-txt">{{ $t("Pickup from") }}</span>
                      <div class="map-add-cls">
                        <a :href="'https://www.google.com/maps?saddr=' +
                          latitude +
                          ',' +
                          longitude +
                          '&daddr=' +
                          restaurantLat +
                          ',' +
                          restaurantLong
                          " target="_blank">></a>
                      </div>
                      <div class="pic-cls-con">
                        <p v-bind:title="restaurantData.location_address">
                          {{ this.restaurantData.location_address }}
                        </p>
                      </div>
                    </div> -->
                  </div>

                  <!-- <div class="full-btn">
                    <a @click="redirectBillingData()" class="btn billing-btn view-dtail-cls">
                      <span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{ getTotalAmount()
                      }}<span class="txtline">{{ $t("View Bill Details") }}</span>
                    </a>
                    <a v-if="parseInt(getTotalItemAmount()) < parseInt(miniumOrderValue)" href="javascript:void(0);"
                      class="prcc-cls">{{ $t("Minimum Order Value") }}
                      <span v-html="$auth.setting.currency" class="currency"></span>{{ miniumOrderValue }}
                    </a>
                    <b-button variant="primary" class="review-cart-btn" disabled v-else-if="is_btn_spinner"><b-spinner
                        small type="grow"></b-spinner>{{ $t("Loading") }}...</b-button>
                    <a v-else href="javascript:void(0);" class="prcc-cls-blr" @click="processToPay()">{{ $t("Proceed To
                                          Pay") }}</a>
                    <router-link ref="
                    " style="display: none !important" :to="{ name: 'payment' }">{{
                      $t("Proceed To Pay") }}</router-link>
                  </div> -->

                  <div class="full-btn">
                    <a @click="redirectBillingData()" class="btn billing-btn view-dtail-cls">
                      <span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{ getTotalAmount()
                      }}<span class="txtline">{{ $t("View Bill Details") }}</span>
                    </a>

                    <!-- <a href="#billing-data" class="btn billing-btn view-dtail-cls">
                      <span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{ getTotalAmount()
                      }}<span class="txtline">{{ $t("View Bill Details") }}</span>
                    </a> -->
                    <!-- <a v-if="this.restaurantData.minimum_admin_commision !== null &&
                      getTotalItemAmount() <
                      parseInt(this.restaurantData.minimum_order_value)
                    " href="javascript:void(0);" class="prcc-cls">{{ $t("Minimum Item Total")
                      }}<span v-html="$auth.setting.currency" class="currency"></span>{{
                        this.restaurantData.minimum_order_value }}</a> -->

                    <a v-if="this.restaurantData.minimum_admin_commision == null &&
                      getTotalItemAmount() <
                      parseInt(this.restaurantData.minimum_order_value)
                    " href="javascript:void(0);" class="prcc-cls">{{ $t("Minimum Item Total")
                    }}<span v-html="$auth.setting.currency" class="currency"></span>{{
                        this.restaurantData.minimum_order_value }}</a>

                    <b-button variant="primary" class="review-cart-btn" disabled v-else-if="is_btn_spinner"><b-spinner
                        small type="grow"></b-spinner>{{ $t("Loading") }}...</b-button>
                    <a v-else href="javascript:void(0);" class="prcc-cls-blr" @click="processToPay()">{{ $t("Proceed To
                      Pay") }}</a>
                    <router-link ref="redirectPayment" style="display: none !important" :to="{ name: 'payment' }">{{
                      $t("Proceed To Pay") }}</router-link>

                  </div>
                </div>
              </div>
              <!--------All Promocode Modal start---------->
              <div class="modal fade order-placed" id="promocodeModal" tabindex="-1"
                aria-labelledby="promocodeModallabel" aria-hidden="true" ref="promocodeModalRef" style="z-index: 9999">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title" id="filterLabel">
                        {{ $t("Promocode") }}
                      </h4>
                      <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body text-center" v-if="promoCodes.length > 0">
                      <div class="order-item d-flex align-items-center justify-content-between"
                        v-for="(offers, index) in this.promoCodes" :key="index">
                        <div class="d-flex align-items-start">
                          <div class="smallimg-squre">
                            <img v-lazy="offers.coupon_image" />
                          </div>
                          <div class="order-data ms-3 d-flex align-items-start justify-content-between w-100">
                            <div class="orders-status-main">
                              <h6 class="mb-0">{{ offers.coupon_name }}</h6>
                              <p class="mt-1" :id="'shortdec' + offers.id" style="display: block">
                                {{ offers.description.substring(0, 54) }}
                                <a v-if="
                                  offers.description && offers.description.length > 54
                                " href="javascript:void(0)" @click="readMore(offers.id)" class="readmore">...more</a>
                              </p>
                              <p :id="'moredesc' + offers.id" style="display: none">
                                {{ offers.description }}
                                <a href="javascript:void(0)" @click="readMore(offers.id)" class="readless">less</a>
                              </p>
                              <div class="promocode mt-2">
                                <p>
                                  {{ $t("Code") }}:
                                  <span class="m-promocode">{{
                                    offers.coupon_code
                                    }}</span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <a class="cart-btn" @click="applyPromocode(offers.coupon_code)">{{
                          $t("Apply")
                        }}</a>
                      </div>
                    </div>
                    <!-- <div v-else class="modal-body text-center"><p>{{$t('No Offers Available')}}</p></div> -->
                    <div v-else class="home-res-not-found my-auto">
                      <div class="nostr-img-cls">
                        <!-- <img src="@/assets/images/no_promocode.png" />               -->
                        <svg width="150" height="150" viewBox="0 0 150 150" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <rect x="24" y="130" width="7" height="1" rx="0.5" fill="#FF8300" />
                          <rect x="34" y="130" width="86" height="1" rx="0.5" fill="#FF8300" />
                          <rect x="122" y="130" width="4" height="1" rx="0.5" fill="#FF8300" />
                          <path
                            d="M80 84.5H106C108.485 84.5 110.5 86.5147 110.5 89V130.5H75.5V89C75.5 86.5147 77.5147 84.5 80 84.5Z"
                            stroke="#FF8300" />
                          <path
                            d="M47 58.5H93C95.4853 58.5 97.5 60.5147 97.5 63V130.5H42.5V63C42.5 60.5147 44.5147 58.5 47 58.5Z"
                            fill="white" stroke="#FF8300" />
                          <line x1="34.3537" y1="49.7104" x2="40.8333" y2="56.19" stroke="#FF8300"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <line x1="46.8974" y1="44.6124" x2="44.5257" y2="53.4636" stroke="#FF8300"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <line x1="0.5" y1="-0.5" x2="9.66346" y2="-0.5"
                            transform="matrix(0.965926 -0.258819 -0.258819 -0.965926 28 62.5439)" stroke="#FF8300"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <line x1="51.5" y1="91.5" x2="75.5" y2="91.5" stroke="#FF8300" stroke-width="3"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <line x1="51.5" y1="101.5" x2="65.5" y2="101.5" stroke="#FF8300" stroke-width="3"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <line x1="51.5" y1="83.5" x2="84.5" y2="83.5" stroke="#FF8300" stroke-width="3"
                            stroke-linecap="round" stroke-linejoin="round" />
                          <rect x="102" y="65" width="19" height="1" rx="0.5" fill="#FF8300" />
                          <rect x="12" y="95" width="19" height="1" rx="0.5" fill="#FF8300" />
                          <rect x="109" y="77" width="19" height="1" rx="0.5" fill="#FF8300" />
                          <circle cx="137.828" cy="107.828" r="2.32787" stroke="#FF8300" />
                          <circle cx="77.8279" cy="47.8279" r="2.32787" stroke="#FF8300" />
                          <circle cx="127.828" cy="47.8279" r="2.32787" stroke="#FF8300" />
                          <circle cx="17.8279" cy="117.828" r="2.32787" stroke="#FF8300" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M16.1986 70.3995C15.9777 70.1787 15.6196 70.1787 15.3988 70.3995L13.7991 71.9992L12.1994 70.3995C11.9785 70.1787 11.6204 70.1787 11.3995 70.3995C11.1787 70.6204 11.1787 70.9785 11.3995 71.1994L12.9992 72.7991L11.3995 74.3987C11.1787 74.6196 11.1787 74.9777 11.3995 75.1986C11.6204 75.4194 11.9785 75.4194 12.1994 75.1986L13.7991 73.5989L15.3988 75.1986C15.6196 75.4194 15.9777 75.4194 16.1986 75.1986C16.4195 74.9777 16.4195 74.6196 16.1986 74.3987L14.5989 72.7991L16.1986 71.1994C16.4195 70.9785 16.4195 70.6204 16.1986 70.3995Z"
                            fill="#FF8300" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M56.6678 34.6119C56.8346 34.3478 56.7558 33.9985 56.4918 33.8317L54.5793 32.6232L55.7877 30.7107C55.9546 30.4467 55.8758 30.0973 55.6117 29.9305C55.3477 29.7636 54.9984 29.8424 54.8315 30.1065L53.623 32.019L51.7106 30.8105C51.4465 30.6437 51.0972 30.7225 50.9303 30.9865C50.7635 31.2506 50.8423 31.5999 51.1063 31.7667L53.0188 32.9752L51.8104 34.8877C51.6435 35.1518 51.7223 35.5011 51.9864 35.6679C52.2504 35.8348 52.5997 35.756 52.7666 35.4919L53.9751 33.5794L55.8875 34.7879C56.1516 34.9548 56.5009 34.876 56.6678 34.6119Z"
                            fill="#FF8300" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M143.787 71.3934C143.787 71.0811 143.534 70.8279 143.221 70.8279L140.959 70.8279L140.959 68.5656C140.959 68.2532 140.706 68 140.393 68C140.081 68 139.828 68.2532 139.828 68.5656L139.828 70.8279L137.566 70.8279C137.253 70.8279 137 71.0811 137 71.3934C137 71.7058 137.253 71.959 137.566 71.959L139.828 71.959L139.828 74.2213C139.828 74.5337 140.081 74.7869 140.393 74.7869C140.706 74.7869 140.959 74.5337 140.959 74.2213L140.959 71.959L143.221 71.959C143.534 71.959 143.787 71.7058 143.787 71.3934Z"
                            fill="#FF8300" />
                          <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M108.787 21.3934C108.787 21.0811 108.534 20.8279 108.221 20.8279L105.959 20.8279L105.959 18.5656C105.959 18.2532 105.706 18 105.393 18C105.081 18 104.828 18.2532 104.828 18.5656L104.828 20.8279L102.566 20.8279C102.253 20.8279 102 21.0811 102 21.3934C102 21.7058 102.253 21.959 102.566 21.959L104.828 21.959L104.828 24.2213C104.828 24.5337 105.081 24.7869 105.393 24.7869C105.706 24.7869 105.959 24.5337 105.959 24.2213L105.959 21.959L108.221 21.959C108.534 21.959 108.787 21.7058 108.787 21.3934Z"
                            fill="#FF8300" />
                        </svg>
                        <h6>{{ $t("No Offers Available") }}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--------All Promocode Modal End---------->
            </div>
            <div v-else class="order-items-main">
              <div class="center-card | d-flex align-items-center justify-content-center">
                <div class="center-card-box | p-3">
                  <div class="full-btn text-center">
                    <div class="crt-img-cls">
                      <img v-lazy="require('@/assets/images/empty_cart.png')" class="img-fluid d-inline-block" />
                    </div>
                    <p class="empty-cart my-4">{{ $t("Cart Empty") }}</p>
                    <a href="javascript:void(0)" class="w-auto d-inline-flex mx-auto" @click="goToHome()">{{ $t("Go
                      Back")}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- porterl delivery charges modal -->
        <div class="modal texes-modal fade" id="portalModal" tabindex="-1" aria-labelledby="portalModallabel"
          aria-hidden="true" ref="portalModalRef" data-bs-backdrop="static" data-bs-keyboard="false"
          style="z-index: 9999">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content texes-modal-content py-4 billing-data">
              <h6 class="d-flex justify-content-center">
                {{ $t("Select delivery Charges") }}
              </h6>
              <ul class="mt-4 overflow-auto">
                <li class="tip-drivers d-flex justify-content-between" v-for="(charge, index) in porterCharges"
                  :key="index">
                  <label :for="'tip_' + index" class="">{{
                    charge.vehicle_name
                  }}</label>
                  <input type="radio" class="visually-hidden" v-model="seletectedPorter" :id="'tip_' + index"
                    :value="charge.minor_amount"
                    @click="getPorterValue(charge.minor_amount, charge.vehicle_name, index)" />
                  <label :for="'tip_' + index" class=""><span v-html="$auth.setting && $auth.setting.currency"
                      class="currency"></span>
                    {{
                      (charge.minor_amount / 100).toFixed(2)
                    }}</label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- porterl delivery charges modal -->

        <!-- Driver Tip Modal-->
        <div class="modal fade" id="driverTipModal" tabindex="-1" aria-labelledby="driverTipModalLabel"
          aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
          <div class="modal-dialog">
            <div class="modal-content h-auto border-0">
              <div class="modal-body">
                <h4>Drivers Tip</h4>
                <p class="mb-3">Enter amount of tip you want to give...</p>
                <input type="text" class="form-control" id="tripValue" v-model="other_driver_tip" placeholder="Amount"
                  @keypress="floatNumberOnly($event)"
                  oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                  minlength="1" maxlength="5" />
                <div class="d-flex align-items-center justify-content-end mt-4">
                  <a href="javascript:void(0)" class="btn me-4" @click="cancelTipModal('')">Cancel</a>
                  <a href="javascript:void(0);" class="btn btn--primary cncl-tip" @click="closeTipModal">Add
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Driver Tip Modal-->

        <!--  Other tex packagine and convince and gst modal-->

        <div class="modal texes-modal fade" id="otherFeesModal" tabindex="-1" aria-labelledby="otherFeesModallabel"
          aria-hidden="true" ref="otherFeesModalRef" style="z-index: 9999">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content texes-modal-content py-4 billing-data p-3">
              <h6 class="d-flex justify-content-center">
                {{ $t("Taxes & Other Fees") }}
              </h6>
              <ul class="mt-4 overflow-auto">
                <li class="d-flex justify-content-between" v-if="this.gstMaxtax !== 0 && this.gstMaxtax &&
                  this.$auth.setting &&
                  (this.$auth.setting.included_tax == 0 ||
                    this.$auth.setting.included_tax == 'Yes')">

                  {{
                    this.$auth.setting &&
                    this.$auth.setting.terminology &&
                    this.$auth.setting.terminology[0].tax_name
                  }}({{ this.gstMaxtax.toFixed(2) }}%)
                  <span><span v-html="$auth.setting.currency" class="currency"></span>{{
                    gstTotalAmount()
                  }}</span>
                </li>

                <li class="d-flex justify-content-between" v-if="packagingCharge > 0">
                  <span>{{
                    this.$auth.setting.terminology[0].packaging_fee
                      ? this.$auth.setting.terminology[0].packaging_fee
                      : "Packaging Fees "
                  }}</span>
                  <span><span v-html="$auth.setting.currency" class="currency"></span>{{
                    parseFloat(packagingCharge).toFixed(2) }}</span>
                </li>

                <li class="d-flex justify-content-between" v-if="restaurantData.restaurant_convince_charge > 0">
                  {{
                    this.$auth.setting.terminology[0].convenience_fee
                      ? this.$auth.setting.terminology[0].convenience_fee
                      : "Convenience Fees"
                  }}
                  <span><span v-html="$auth.setting.currency" class="currency"></span>{{
                    $formatPrice(getConvenienceCharge()) }} </span>
                </li>


                <li class="d-flex justify-content-between"
                  v-if="this.getDeliveryZoneSettings.is_delivery_charge_tax_enable == 'ON' && isPickupDelivery == 1 && this.$auth.setting.is_active_porter != 'Yes' && this.$auth.setting.porter_delivery_charges != 'Yes'">
                  {{ "Delivery Charges" }} {{ this.$auth.setting.terminology[0].tax_name }} {{
                    `(${this.getDeliveryZoneSettings.delivery_charge_tax}%)` }}
                  <span><span v-html="$auth.setting.currency" class="currency"></span>{{
                    this.getDeliveryTax().toFixed(2) }} </span>
                </li>


                <li class="d-flex justify-content-between"
                  v-if="this.restaurantData.minimum_admin_commision !== 0 && this.restaurantData.minimum_admin_commision !== null && this.adminComissionValue !== 0">
                  {{ "Short Order fee" }}
                  <span><span v-html="$auth.setting.currency" class="currency"></span>{{
                    this.getShortFees().toFixed(2) }} </span>
                </li>

              </ul>
              <div class="modal-footer">
                <div class="full-btn mt-4 bottom-stiky border-0 w-100" @click="closeTexModal">
                  <button>{{ $t("Got it") }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--  Other tex packagine and convince and gst modal-->

        <!-- image modal preview start-->
        <div class="modal fade" id="imageModal" tabindex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  {{ itemName }}
                </h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <img v-if="selectedImage" v-lazy="selectedImage" class="img-fluid" alt="Image Preview" />
            </div>
          </div>
        </div>
        <!-- image modal preview end-->
        <div class="modal fade order-img" data-backdrop="static" data-keyboard="false" id="imgOpen" tabindex="-1"
          aria-labelledby="imgOpen" aria-hidden="true" ref="imgOpenModal" style="z-index: 9999">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content order-img-modal">
              <div class="modal-body text-center">
                <a data-bs-dismiss="modal" class="lnk crosclose">X</a>
                <img v-lazy="imageUrl" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import NavBar from "../components/NavBar.vue";
import DeliveryTime from "../components/DeliveryTime.vue";
import Footer from "../components/Footer.vue";
import moment from "moment";
import VueSkeletonLoader from "skeleton-loader-vue";
import noImge from "@/assets/images/place_holder.png";

export default {
  components: {
    NavBar,
    DeliveryTime,
    Footer,
    "skeleton-loader-vue": VueSkeletonLoader,
  },
  data() {
    return {
      moment: moment,
      restaurantData: "",
      address: "",
      gstMaxtax: 0,
      cartItemCount: 1,
      promocode: "",
      promo_code_type: "",
      promoError: "",
      promoDiscount: 0,
      promoSuggetion: "",
      isPromocodeApply: false,
      isPopup: true,
      promoCashback: 0,
      promoCodeType: 0,
      contactDelivery: 0,
      onpageDelivery: 0,
      place_order_parameter: {},
      shiping_id: "",
      coupon_id: "",
      instructions: "",
      restaurantId: 0,
      scheduleDateTime: {},
      promoCodes: [],
      latitude: "",
      longitude: "",
      restaurantLat: "",
      restaurantLong: "",
      page: "review_page",
      isAsapEnable: false,
      timeFormat: "HH:mm:ss",
      isRestaurantClosed: false,
      isSameDayDelivery: false,
      is_btn_spinner: true,
      images: [],
      orderImagesId: "",
      loading: false,
      deliveryCharge: 0.0,
      noImge,
      instructionPlaceholder: "",
      applyPromoCodeResult: {},
      deliveryTypeSlots: "",
      isDunzoAlert: false,
      packagingCharge: 0,
      driver_tip: 0,
      other_driver_tip: "",
      new_driver_tip: 0,
      is_driver_tip: false,
      radioCounts: 0,
      tipsClick: [],
      convince_charge: 0,
      rightClass: "",
      isTemplateShow: false,
      parsedDriverTips: [],
      selectedImage: null,
      itemName: "",
      // area_not_available: true,
      reason: null,
      imageUrl: "",
      is_other_Tip: false,
      others_fees: "",
      autoApplyCode: "1",
      deliveryInstruction: [],
      Instruction: [],
      isInstruction: {},
      time_slots: 0,
      emailPromptDismissed: false,
      selectedIndex: null,
      deliveryChargeTax: 0,
      adminComissionValue: 0,
      porterCharges: [],
      seletectedPorter: 0,
      seletectedPorterIndex: null,
      activePorterIndex: null,
      showPorterChrages: true

    };
  },

  beforeCreate() {
    let localCart = JSON.parse(localStorage.getItem("cart"));
    if (localCart && localCart != "" && localCart != null && localCart.length > 0) {
      this.$store.commit("product/setCart", localCart);
    }
    let pickupDeliveryFlag = localStorage.getItem("pickupDeliveryFlag");
    if (pickupDeliveryFlag) {
      this.$store.commit("order/pickupdelivery", pickupDeliveryFlag);
    }
  },

  mounted() {

    let cardTopJson = localStorage.getItem("place_order_data");
    if (cardTopJson) {
      try {
        let cardTop = JSON.parse(cardTopJson);
        if (cardTop) {
          if (cardTop.delivery_instruction) {
            this.Instruction = JSON.parse(cardTop.delivery_instruction)
            this.Instruction.forEach(instruction => {
              this.$set(this.isInstruction, instruction.id, true);
            });
          }
          if (cardTop.driver_tip) {
            this.driver_tip = cardTop.driver_tip;
            this.is_other_Tip = false;
          }
          if (cardTop.coupon_redeem) {
            this.promocode = cardTop.coupon_redeem;
          }
          if (cardTop.promo_code_type) {
            this.promoCodeType = cardTop.promo_code_type;
          }
          if (cardTop.cashback) {
            this.promoCashback = cardTop.cashback;
          }
          if (cardTop.promoDiscount) {
            this.promoDiscount = cardTop.promoDiscount;
          }
          if (cardTop.coupon_id) {
            this.coupon_id = cardTop.coupon_id;
          }
          if (cardTop.driver_tip && cardTop.is_other_Tip) {
            this.other_driver_tip = cardTop.driver_tip;
            this.is_other_Tip = true;
          }
          if (cardTop && cardTop.autoApplyCode) {
            this.autoApplyCode = cardTop.autoApplyCode;
          }
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
        // Handle the error as needed
      }
    }

    setTimeout(() => {
      if (this.$auth.user) {
        // this.getPorterCharges()
        // const containsKey = (obj, key) => obj && Object.keys(obj).includes(key);
        // // this.deliveryInstruction = JSON.parse(this.$auth.setting.delivery_instruction)
        // this.deliveryInstruction = this.$auth.setting.delivery_instruction    // dev server site this code 

        // //  deonde live serve 
        // if (containsKey(this.$auth.setting, 'delivery_instruction')) {
        //   this.deliveryInstruction = this.$auth.setting.delivery_instruction;
        // } else {
        //   // Handle the case when delivery_instruction is missing
        //   this.deliveryInstruction = []; // Assign empty array or any fallback
        // }

        if (this.$auth.setting && typeof this.$auth.setting.delivery_instruction != 'undefined') {
          this.deliveryInstruction = this.$auth.setting.delivery_instruction;
        } else {
          this.deliveryInstruction = [];
        }
        this.isTemplateShow = true;
        this.getRestaurantInfo();

        // this.autoApplyPromo()
        this.token = this.$auth.user.token;
        this.userId = this.$auth.user.user_id;
        this.latitude = localStorage.getItem("latitude");
        this.longitude = localStorage.getItem("longitude");
        let currentTime = moment().format(this.timeFormat);
        if (this.$auth.setting) {
          let system_start_time =
            this.$auth.setting && this.$auth.setting.system_start_time != ""
              ? this.$auth.setting.system_start_time.split(" ").pop()
              : "00:00:00";
          let system_end_time =
            this.$auth.setting && this.$auth.setting.system_end_time != ""
              ? this.$auth.setting && this.$auth.setting.system_end_time.split(" ").pop()
              : "23:59:00";
          this.isSameDayDelivery =
            this.$auth.setting && this.$auth.setting.is_same_day_delivery == 0
              ? false
              : true;
          let beforeTime = moment(system_start_time, this.timeFormat);
          let afterTime = moment(system_end_time, this.timeFormat);
          if (moment(currentTime, this.timeFormat).isBetween(beforeTime, afterTime)) {
            this.isAsapEnable = true;
          } else {
            this.isAsapEnable = false;
          }
        }
      } else {
        this.$router.push({ name: "sign-in" });
      }
      if (
        localStorage.getItem("orderUploadImages") != "" &&
        localStorage.getItem("orderUploadImages") != null
      ) {
        let ordImg = [];
        this.images = JSON.parse(localStorage.getItem("orderUploadImages"));
        this.images.forEach((imgid) => {
          ordImg.push(imgid.id);
        });
        this.orderImagesId = ordImg.join(",");
      }
      if (localStorage.getItem("updateAlert")) {
        this.$toasted.success(this.$t("Cart updated"), {
          duration: 2000,
        });
        localStorage.removeItem("updateAlert");
      }

    }, 500);
  },

  computed: {
    ...mapGetters("product", ["cart"]),
    ...mapGetters("restaurant", ["restaurantDetails"]),
    ...mapGetters("address", ["addressList", "primaryAddress"]),
    ...mapGetters("order", ["isPickupDelivery"]),
    ...mapGetters("setting", ["getsetting", "getDeliveryZoneSettings"]),

    getCurrentDate() {
      let currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      let day = ("0" + currentDate.getDate()).slice(-2);
      let hours = ("0" + currentDate.getHours()).slice(-2);
      let minutes = ("0" + currentDate.getMinutes()).slice(-2);
      let seconds = ("0" + currentDate.getSeconds()).slice(-2);

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    isBrowser() {
      return typeof window !== 'undefined';
    },

    miniumOrderValue() {
      var value = 0;
      // if (
      //   this.restaurantDetails.minimum_order_value <=
      //   this.$auth.setting.min_order_value
      // ) {
      //   value = this.$auth.setting.min_order_value;
      // } else {
      value = this.restaurantDetails.minimum_order_value;
      // }
      return value;
    },
    checkPickupStatus() {
      return this.$auth.setting.is_pickup_enable;
    },
  },
  methods: {
    ...mapActions("product", ["removeProductFromCart"]),
    ...mapActions("address", ["getPrimaryAddress"]),
    ...mapActions("restaurant", [
      "getRestaurantDetails",
      "checkVendorRadius",
      "checkVendorMenuWithPrice",
      "getDunzoQuotationData",
    ]),
    ...mapActions("setting", ["getDeliveryZoneSetting"]),
    ...mapActions("promocode", [
      "promocodeRedeem",
      "updatePromoCode",
      "autoApplyCouponRedeem",
    ]),
    ...mapActions("order", [
      "postUploadOrderImages",
      "postremoveOrderImages",
      "getOrderDistancekm",
      "deliveryZone",
      "getPorterDeliveryCharges"
    ]),
    ...mapMutations("product", [
      "decrementProductInventary",
      "IncrementItemQuantity",
      "customizeCartUpdate",
      "clearCartItems",
    ]),
    ...mapMutations("order", ["placeOrderData", "clearPlaceOrderData", "pickupdelivery"]),
    async getAllDeliveryZoneSeeting(latitude, longitude) {
      if (!latitude || !longitude) {
        latitude = this.latitude;
        longitude = this.longitude;
      }
      try {
        const data = await this.getDeliveryZoneSetting({
          vendor_id: this.$auth.getVendorId(),
          is_language: this.$store.state.lang.locale,
          lat: latitude,
          lan: longitude,
          // order_web: host
        });
        if (
          data.Result.is_delivery_zone_available == null ||
          data.Result.is_delivery_zone_available == "No"
        ) {
          this.$swal({
            title: this.$auth.setting.app_name,
            html: data.Result.not_available_delivery_zone_reason,
          });
          if (data.code == 101) {
            this.$swal({
              title: this.$auth.setting.app_name,
              html:
                "Sorry, At this moment we are not providing delivery service at your selected location",
            });
          }
          return false;
        }
        return false;
      } catch (error) {
        console.error("Error in getAllDeliveryZoneSetting:", error);
      }
    },
    openImagePreview(image, name) {
      this.selectedImage = image;
      this.itemName = name;
    },

    encodeBase64(value) {
      // Ensure window.btoa is available
      return this.isBrowser ? window.btoa(value) : '';
    },
    //save your item price
    savedPrice() {
      let savemrpTotal = 0;
      let savemrp = 0;
      this.cart.forEach((item) => {
        if (item.price < item.mrp) {
          savemrp = item.mrp - item.price;
          savemrpTotal += savemrp * item.quantity;
        }
      });
      return savemrpTotal;
    },
    redirectBillingData() {
      var myDiv = document.getElementById("billing-data");
      var offsetTop = myDiv.offsetTop;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    },
    deliveryModalOpen() {
      // localStorage.getItem('selectedIndex')
      this.selectedIndex = this.shiping_id
      this.driver_tip = 0;
      this.promocode = "";
      // this.promoSuggetion = "";
      this.isPromocodeApply = false;
      this.promoDiscount = 0;
      this.promoCashback = 0;
      // this.promocodeType = 0;
      // localStorage.removeItem('promocodeType');
      $(".location-modal").addClass("heightModal");
      $("#deliveryModal").modal("show");
    },

    openImg(element) {
      this.imageUrl = element.image;
      $("#imgOpen").modal("show");
    },

    //Item Text Display
    itemTexDisplay() {
      let itemTex = 0;
      this.cart.forEach((item) => {
        itemTex += item.item_tax * item.quantity;
      });
      return itemTex.toFixed(2);
    },

    // auto apply promocode by defult apply code

    //Promocode modal
    promocodeModal() {
      localStorage.removeItem("place_order_data");
      // localStorage.removeItem('promocodeType');
      this.latitude = localStorage.getItem("latitude");
      this.longitude = localStorage.getItem("longitude");
      this.promoCodes = [];
      // this.promocode = ""
      let deliveryType = "Delivery";
      if (this.isPickupDelivery == 1 || this.isPickupDelivery == -1) {
        deliveryType = "Delivery";
      } else {
        deliveryType = "Pickup";
      }
      this.updatePromoCode({
        vendor_id: this.$auth.getVendorId(),
        coupon_type: deliveryType,
        restaurant_id: this.restaurantData.restaurant_id,
        user_id: this.userId,
        cityname: localStorage.getItem("city"),
        latitude: this.latitude,
        longitude: this.longitude,
      }).then((data) => {
        if (data.code == 200) {
          this.promoCodes = data.Result;
          // coupon_id = this.data.Result.coupon_id
          this.promoCodeType = 0;
        }
        $("#promocodeModal").modal("show");
      });
    },

    onSetOrderTime(value) {
      this.scheduleDateTime = value;
    },

    async getAddress(lat1, lon1, lat2, lon2,) {
      // localStorage.removeItem('promocodeType'); 
      this.selectedIndex = this.shiping_id
      let response = await this.getAllDeliveryZoneSeeting(
        this.primaryAddress.latitude,
        this.primaryAddress.longitude
      );
      if (response) {
        this.$swal({
          title: this.$auth.setting.app_name,
          // html: this.getDeliveryZoneSettings.not_available_delivery_zone_reason,
          html:
            "Sorry, At this moment we are not providing delivery service at your selected location",
        });
        return true;
      }

      if (Object.keys(this.primaryAddress).length != 0) {
        this.is_btn_spinner = false;
        this.address =
          this.primaryAddress.flat_no +
          " " +
          this.primaryAddress.house_name +
          " " +
          this.primaryAddress.Shiping_address;
        this.shiping_id = this.primaryAddress.shiping_id;
        await this.getDeliveryChrge();
        // this.autoApplyPromo()
      } else {
        this.address = "";
        this.is_btn_spinner = false;
      }

      if (
        Object.keys(this.primaryAddress).length == 0 ||
        this.primaryAddress.deliveryAddress == ""
      ) {
        this.$router.push({ name: "add-address" });
        // this.deliveryModalOpen();
        // // if(this.addressList.length > 0){
        // // }else{
        // }
      }
    },

    //Get Restaurant Information
    getRestaurantInfo() {
      if (this.cart.length) {
        this.restaurantId = this.cart[0].restaurant_id;
        this.getRestaurantDetails({
          vendor_id: this.$auth.getVendorId(),
          restaurant_id: this.restaurantId,
          is_langauge: this.$store.state.lang.locale,
          latitude: parseFloat(localStorage.getItem("latitude")),
          longitude: parseFloat(localStorage.getItem("longitude")),
          delivery_type_time_slots: this.isPickupDelivery,
        }).then((data) => {
          if (data.code == 200) {
            this.restaurantData = data.Result;
            this.restaurant_service_tax = data.Result.restaurant_service_tax;
            this.restaurantLat = data.Result.latitude;
            this.restaurantLong = data.Result.longitude;
            this.deliveryTypeSlots = data.Result.delivery_type_time_slots;
            this.instructionPlaceholder =
              this.$t("any special request for ") + "Restaurants";
            this.isRestaurantClosed = data.Result.restaurant_on_off == "0" ? false : true;
            // this.getDeliveryChrge();
            this.getShortFees()
          }
        });
      }
    },

    getShortFees() {
      if (this.restaurantData.admin_commision_type == 'commission_in' && this.restaurantData.minimum_admin_commision !== 0 && this.restaurantData.minimum_admin_commision !== null) {
        this.adminComissionValue = this.restaurantData.minimum_admin_commision - this.restaurantData.admin_commision
      }
      if (this.restaurantData.admin_commision_type == 'commission_in_percentage') {
        // Calculate the percentage commission based on getTotalItemAmount
        // const percentageCommission = ((this.getTotalItemAmount() * this.restaurantData.admin_commision) / 100).toFixed(2);
        const percentageCommission = (this.getTotalItemAmount() * this.restaurantData.admin_commision) / 100;
        // return ((this.getTotalItemAmount() * gstServiceTax) / 100).toFixed(2);
        if (percentageCommission > this.restaurantData.minimum_admin_commision) {
          this.adminComissionValue = 0;
        }
        var shortOrderFee = this.restaurantData.minimum_admin_commision - percentageCommission;
        shortOrderFee = Math.round(shortOrderFee * 100) / 100;

        if (shortOrderFee > 0) {
          this.adminComissionValue = shortOrderFee;
        }
      }
      return this.adminComissionValue;
    },

    //Display Total Amount Of item cart
    getTotalItemPrice(item) {
      return item.price * item.quantity;
    },

    //Get Total Amount of Items
    getTotalItemAmount() {
      let itmTotal = 0;
      let itemCount = 0;
      let packCharge = 0;
      this.cart.forEach((item) => {
        itmTotal += item.price * item.quantity;
        itemCount++;
        packCharge += item.packaging_charge * item.quantity;
      });
      let restPackCharge = 0;
      if (
        this.restaurantData.restaurant_packaging_type &&
        this.restaurantData.restaurant_packaging_type == "fixed" &&
        this.restaurantData.restaurant_packaging_charge > 0
      ) {
        restPackCharge = parseFloat(this.restaurantData.restaurant_packaging_charge);
      } else if (
        this.restaurantData.restaurant_packaging_type &&
        this.restaurantData.restaurant_packaging_type == "percentage" &&
        this.restaurantData.restaurant_packaging_charge > 0
      ) {
        restPackCharge = parseFloat(
          parseFloat(
            ((itmTotal / 100) * this.restaurantData.restaurant_packaging_charge).toFixed(
              2
            )
          )
        );
      }
      this.packagingCharge = packCharge + restPackCharge;
      this.cartItemCount = itemCount;
      return this.$formatPrice(itmTotal);
      //return parseFloat(itmTotal).toFixed(2);
    },

    //Increament Item
    plusItem(item, index) {
      //const cartItemIndex = this.cart.findIndex(itm => parseInt(itm.item_id) === parseInt(item.item_id));
      let cartItem = { cartItemIndex: index, product: item };
      if (item.itemTotalQuantity > item.quantity) {
        this.$store.commit("product/IncrementItemQuantity", cartItem);
        this.isPopup = false;
        this.getShortFees()
        if (this.seletectedPorter) {
          this.deliveryCharge = this.seletectedPorter / 100
        } else {
          this.getDeliveryChrge();
        }
      } else {
        alert(this.$t("Maximum items reached"));
      }
      if (this.applyPromoCodeResult) {
        this.applyPromocodeByItem();
      }
    },

    //Decrement Item
    minusItem(cartProduct, index) {
      if (cartProduct.quantity == "1" || cartProduct.quantity == "0") {
        this.deleteProduct(index);
      } else {
        this.isPopup = false;
        this.$store.commit("product/decrementProductInventary", index);
        this.getShortFees()
        if (this.seletectedPorter) {
          this.deliveryCharge = this.seletectedPorter / 100
        } else {
          this.getDeliveryChrge();
        }
      }
      if (this.applyPromoCodeResult) {
        this.applyPromocodeByItem();
      }
    },

    //Remove Item from cart
    deleteProduct(index) {
      this.$store.commit("order/clearPlaceOrderData");
      this.$store.dispatch("product/removeProductFromCart", index);
    },

    //GST Total Amount Display
    gstTotalAmount() {
      if (
        this.$auth.setting &&
        (this.$auth.setting.included_tax == 0 || this.$auth.setting.included_tax == "Yes")
      ) {
        let gstServiceTax = parseFloat(this.restaurantDetails.restaurant_service_tax);
        this.gstMaxtax = gstServiceTax;

        let gst = parseFloat(this.gstMaxtax) * parseFloat(this.getTotalItemAmount()) / 100;
        // Round up to 2 decimal places
        gst = Math.ceil(gst * 100) / 100;

        // return ((this.getTotalItemAmount() * gstServiceTax) / 100).toFixed(2);
        // return ((this.getTotalItemAmount() / 100) * gstServiceTax).toFixed(2);
        return gst.toFixed(2);
      } else {
        return 0;
      }
    },

    getConvenienceCharge() {
      if (this.restaurantData.restaurant_convince_type == "fixed") {
        return (this.convince_charge = this.restaurantData.restaurant_convince_charge.toFixed(
          2
        ));
      } else {
        return (this.convince_charge = (
          (this.getTotalItemAmount() * this.restaurantData.restaurant_convince_charge) /
          100
        ).toFixed(2));
      }
    },


    //Delivery Charge Total Amount Display
    // getDeliveryChrge() {
    //   if (
    //     this.$auth.setting.third_party_delivery_services &&
    //     this.$auth.setting.third_party_delivery_services.length == 1 &&
    //     this.$auth.setting.third_party_delivery_services.includes("dunzo")
    //   ) {
    //     this.is_btn_spinner = true;
    //     this.getDunzoQuotationData({
    //       address_id: this.shiping_id,
    //       is_langauge: this.$store.state.lang.locale,
    //       restaurant_id: this.restaurantId,
    //       vendor_id: this.$auth.getVendorId(),
    //     }).then((data) => {
    //       if (data.code == 200) {
    //         this.deliveryCharge = data.Result.estimated_price;
    //         this.isDunzoAlert = false;
    //         this.is_btn_spinner = false;
    //       } else {
    //         this.isDunzoAlert = true;
    //         this.$swal({
    //           title: this.restaurantData.name,
    //           html:
    //             "Sorry, At this moment we are not providing delivery service at your selected location.",
    //         });
    //         this.is_btn_spinner = false;
    //       }
    //     });
    //   } else {
    //     let vardeliveryCharge = 0;
    //     if (this.isPickupDelivery == 1) {
    //       // this.driver_tip = 0;
    //       // this.new_driver_tip = 0;
    //       let clntLatlng = this.primaryAddress;
    //       let host =
    //         window.location.hostname == "localhost"
    //           ? "dev.deonde.co"
    //           : window.location.hostname;
    //       this.deliveryZone({
    //         vendor_id: this.$auth.getVendorId(),
    //         is_langauge: this.$store.state.lang.locale,
    //         latitude: clntLatlng.latitude,
    //         longitude: clntLatlng.longitude,
    //         order_web: host,
    //       }).then((data) => {
    //         if (data.settings.delivery_charges_type == "Distance") {
    //           let unit = "K";
    //           if (data.settings.distance_metric == "Miles") {
    //             unit = "N";
    //           }
    //           let clntLatlng = this.primaryAddress;
    //           let restLatlng = this.restaurantDetails;
    //           if (
    //             clntLatlng.latitude != undefined &&
    //             clntLatlng.longitude != undefined &&
    //             restLatlng.latitude != undefined &&
    //             restLatlng.longitude != undefined
    //           ) {
    //             this.getDeliveryLocation(
    //               clntLatlng.latitude,
    //               clntLatlng.longitude,
    //               restLatlng.latitude,
    //               restLatlng.longitude,
    //               unit
    //             );
    //             // this.driver_tip = 0;
    //             // this.new_driver_tip = 0;
    //           }
    //           // this.promoDiscount = 0
    //           this.parsedDriverTips = this.getDeliveryZoneSettings.driver_tip;
    //         } else if (data.settings.delivery_charges_type == "Amount") {
    //           let checkValue = false;
    //           this.parsedDriverTips = this.getDeliveryZoneSettings.driver_tip;


    //           if (this.getDeliveryZoneSettings.is_delivery_charge_tax_enable == 'ON') {
    //             const taxPercentage = this.getDeliveryZoneSettings.delivery_charge_tax;
    //             this.deliveryChargeTax = (this.deliveryCharge * taxPercentage) / 100;
    //             // this.deliveryChargeTax = taxAmount
    //           } else {
    //             this.deliveryChargeTax = 0
    //           }

    //           const totalAmount = parseInt(this.getTotalItemAmount());
    //           
    //           const chargeInRange = data.settings.charges.find((charge) => {
    //             // this.promoDiscount = this.deliveryCharge
    //             return charge.min_value <= totalAmount && totalAmount <= charge.max_value;
    //           });


    //           if (chargeInRange) {
    //             this.deliveryCharge = chargeInRange.delivery_charges;

    //             if (this.getDeliveryZoneSettings.is_delivery_charge_tax_enable == 'ON') {
    //               const taxPercentage = this.getDeliveryZoneSettings.delivery_charge_tax;
    //               this.deliveryChargeTax = (this.deliveryCharge * taxPercentage) / 100;
    //               // this.deliveryChargeTax = taxAmount
    //             } else {
    //               this.deliveryChargeTax = 0
    //             }

    //             localStorage.setItem("deliveryCharges", this.deliveryCharge);

    //             if (this.autoApplyCode == "1") {
    //               this.autoApplyPromo();
    //             }
    //           } else {
    //             if (this.autoApplyCode == "1") {
    //               this.autoApplyPromo();
    //             }
    //             const lastChargeIndex = data.settings.charges.length - 1;
    //             if (
    //               parseInt(data.settings.charges[0].min_value) >= parseInt(totalAmount)
    //             ) {
    //               this.deliveryCharge = data.settings.charges[0].delivery_charges;
    //             } else if (
    //               data.settings.charges[lastChargeIndex].max_value <= totalAmount
    //             ) {
    //               this.deliveryCharge =
    //                 data.settings.charges[lastChargeIndex].delivery_charges;

    //               if (this.getDeliveryZoneSettings.is_delivery_charge_tax_enable == 'ON') {
    //                 const taxPercentage = this.getDeliveryZoneSettings.delivery_charge_tax;
    //                 this.deliveryChargeTax = (this.deliveryCharge * taxPercentage) / 100;
    //                 // this.deliveryChargeTax = taxAmount
    //               } else {
    //                 this.deliveryChargeTax = 0
    //               }
    //             }
    //           }
    //         }
    //       });
    //     } else {
    //       this.autoApplyPromo();
    //       // this.driver_tip = 0;
    //       this.new_driver_tip = 0;
    //       vardeliveryCharge = 0;
    //     }
    //     // this.deliveryCharge = vardeliveryCharge.toFixed(2);
    //   }
    // },

    getPorterCharges() {
      // let clntLatlng = this.primaryAddress;
      // let restLatlng = this.restaurantDetails;
      if (this.$auth.setting.is_active_porter == "Yes") {
        this.getPorterDeliveryCharges({
          vendor_id: this.$auth.getVendorId(),
          customer_id: this.$auth.user.user_id,
          pickup_latitude: localStorage.getItem("storelat"),
          pickup_longitude: localStorage.getItem("storelng"),
          drop_latitude: localStorage.getItem("latitude"),
          drop_longitude: localStorage.getItem("longitude")
        }).then((data) => {
          if (data.code == 200) {
            this.porterCharges = data.result
            if (this.isPickupDelivery == 1 && this.$auth.setting.is_active_porter == "Yes" && this.$auth.setting.porter_delivery_charges == "Yes") {
              this.deliveryCharge = 0
              this.showPorterChrages = true
              // $("#portalModal").modal("show");
            }
          } else if (data.code == 101) {
            // $("#portalModal").modal("hide");
            this.showPorterChrages = false
            this.deliveryCharge = 0
          }

        })
      }
    },

    // getDeliveryTax() {
    //   if (this.getDeliveryZoneSettings.is_delivery_charge_tax_enable == 'ON' && this.$auth.setting.is_active_porter != "Yes" && this.$auth.setting.porter_delivery_charges != "Yes") {
    //     const taxPercentage = this.getDeliveryZoneSettings.delivery_charge_tax;
    //     this.deliveryChargeTax = (this.deliveryCharge * taxPercentage) / 100;
    //   }
    //   return this.deliveryChargeTax
    // },

    getDeliveryTax() {
      if (
        this.getDeliveryZoneSettings.is_delivery_charge_tax_enable == 'ON' &&
        this.$auth.setting.is_active_porter != "Yes" &&
        this.$auth.setting.porter_delivery_charges != "Yes"
      ) {
        const taxPercentage = this.getDeliveryZoneSettings.delivery_charge_tax;
        this.deliveryChargeTax = (this.deliveryCharge * taxPercentage) / 100;
      }
      return this.promoCodeType == 5 ? 0 : this.deliveryChargeTax;
    },


    getDeliveryChrge() {
      this.getPorterCharges()
      if (
        this.$auth.setting.third_party_delivery_services &&
        this.$auth.setting.third_party_delivery_services.length == 1 &&
        this.$auth.setting.third_party_delivery_services.includes("dunzo")
      ) {
        this.is_btn_spinner = true;

        this.getDunzoQuotationData({
          address_id: this.shiping_id,
          is_langauge: this.$store.state.lang.locale,
          restaurant_id: this.restaurantId,
          vendor_id: this.$auth.getVendorId(),
        }).then((data) => {
          if (data.code == 200) {
            this.deliveryCharge = data.Result.estimated_price;
            this.isDunzoAlert = false;
            this.is_btn_spinner = false;
          } else {
            this.isDunzoAlert = true;
            this.$swal({
              title: this.restaurantData.name,
              html: "Sorry, At this movement we are not providing delivery service at your selected location.",
            });
            this.is_btn_spinner = false;
          }
        });
      } else {
        let vardeliveryCharge = 0;
        if (this.isPickupDelivery == 1) {
          // this.driver_tip = 0;
          // this.new_driver_tip = 0;
          let clntLatlng = this.primaryAddress;
          let host = window.location.hostname == 'localhost' ? 'dev.deonde.co' : window.location.hostname;
          this.deliveryZone({
            vendor_id: this.$auth.getVendorId(),
            is_langauge: this.$store.state.lang.locale,
            latitude: clntLatlng.latitude,
            longitude: clntLatlng.longitude,
            order_web: host,
          }).then((data) => {
            if (data.settings.delivery_charges_type == "Distance") {
              let unit = "K";
              if (data.settings.distance_metric == "Miles") {
                unit = "N";
              }
              let clntLatlng = this.primaryAddress;
              let restLatlng = this.restaurantDetails;
              if (clntLatlng.latitude != undefined && clntLatlng.longitude != undefined && restLatlng.latitude != undefined && restLatlng.longitude != undefined
              ) {
                this.getDeliveryLocation(clntLatlng.latitude, clntLatlng.longitude, restLatlng.latitude, restLatlng.longitude, unit);
                // this.driver_tip = 0;
                // this.new_driver_tip = 0;
              }
              // this.promoDiscount = 0
              this.parsedDriverTips = this.getDeliveryZoneSettings.driver_tip;
            } else if (data.settings.delivery_charges_type == "Amount") {

              let checkValue = false;
              this.parsedDriverTips = this.getDeliveryZoneSettings.driver_tip;
              // if (this.getDeliveryZoneSettings.is_delivery_charge_tax_enable === 'ON') {
              //   const taxPercentage = this.getDeliveryZoneSettings.delivery_charge_tax;
              //   this.deliveryChargeTax = (this.deliveryCharge * taxPercentage) / 100;
              //   // this.deliveryChargeTax = taxAmount
              // } else {
              //   this.deliveryChargeTax = 0
              // }

              const totalAmount = parseInt(this.getTotalItemAmount());
              const chargeInRange = data.settings.charges.find(charge => {
                // this.promoDiscount = this.deliveryCharge
                return charge.min_value <= totalAmount && totalAmount <= charge.max_value;
              });
              if (chargeInRange) {
                this.getPorterCharges()
                if (this.$auth.setting.is_active_porter == "Yes" && this.$auth.setting.porter_delivery_charges == "Yes") {
                  this.deliveryCharge = 0
                } else {
                  this.deliveryCharge = chargeInRange.delivery_charges;
                }
                localStorage.setItem('deliveryCharges', this.deliveryCharge)
                // if (data.settings.is_delivery_charge_tax_enable === 'ON') {
                //   const taxPercentage = data.settings.delivery_charge_tax;
                //   this.deliveryChargeTax = (this.deliveryCharge * taxPercentage) / 100;
                //   // this.deliveryChargeTax = taxAmount
                // } else {
                //   this.deliveryChargeTax = 0
                // }

                if (this.autoApplyCode == "1") {
                  this.autoApplyPromo()
                }
              } else {
                if (this.autoApplyCode == "1") {
                  this.autoApplyPromo()
                }
                const lastChargeIndex = data.settings.charges.length - 1;
                if (parseInt(data.settings.charges[0].min_value) >= parseInt(totalAmount)) {
                  this.deliveryCharge = data.settings.charges[0].delivery_charges;
                } else if (data.settings.charges[lastChargeIndex].max_value <= totalAmount) {
                  if (this.$auth.setting.is_active_porter == "Yes" && this.$auth.setting.porter_delivery_charges == "Yes") {
                    this.deliveryCharge = 0
                  } else {
                    this.deliveryCharge = data.settings.charges[lastChargeIndex].delivery_charges;
                  }
                }
              }
            }
          });
        }
        else {
          this.autoApplyPromo()
          // this.driver_tip = 0;
          this.new_driver_tip = 0;
          vardeliveryCharge = 0;
        }
        // this.deliveryCharge = vardeliveryCharge.toFixed(2);
      }
    },

    //Get Delivery Location Distance Find
    getDeliveryLocation(lat1, lon1, lat2, lon2, unit) {
      if (this.$auth.setting) {
        let dist = 0;
        let deliveryCharge = [];
        let clntLatlng = this.primaryAddress;
        let host = window.location.hostname;
        deliveryCharge = this.getDeliveryZoneSettings.charges;
        // if (this.isPickupDelivery == 1 && this.$auth.setting.is_active_porter == "Yes" && this.$auth.setting.porter_delivery_charges == "Yes") {
        //   $("#portalModal").modal("show");
        // }
        // this.getPorterCharges()
        if (
          lat1 != undefined &&
          lon1 != undefined &&
          lat2 != undefined &&
          lon2 != undefined
        ) {
          this.getOrderDistancekm({
            vendor_id: this.$auth.getVendorId(),
            useraddress_latitude: lat1,
            useraddress_longitude: lon1,
            store_latitude: lat2,
            store_longitude: lon2,
          }).then((data) => {
            if (data.code == 200) {
              let distancekm = data.Result.distance_km;
              let checkKM = false;
              deliveryCharge.forEach((charge) => {
                // let promocodeType = JSON.parse(localStorage.getItem('promocode'))
                // this.driver_tip = 0
                if (distancekm > charge.min_value && distancekm <= charge.max_value) {
                  dist = charge.delivery_charges;
                  checkKM = true;
                  this.deliveryCharge = parseFloat(dist);

                  // if (this.getDeliveryZoneSettings.is_delivery_charge_tax_enable == 'ON' && this.isPickupDelivery == 1) {
                  //   const taxPercentage = this.getDeliveryZoneSettings.delivery_charge_tax;
                  //   this.deliveryChargeTax = (this.deliveryCharge * taxPercentage) / 100;
                  //   // this.deliveryChargeTax = taxAmount
                  // } else {
                  //   this.deliveryChargeTax = 0
                  // }

                  localStorage.setItem("deliveryCharges", this.deliveryCharge);
                  let currency = "";
                  if (
                    document.getElementById("currency") &&
                    document.getElementById("currency").childNodes[0]
                  ) {
                    currency = document.getElementById("currency").childNodes[0]
                      .textContent;
                  }
                  if (this.autoApplyCode == "1") {
                    this.autoApplyPromo();
                  }
                }
              });
              if (!checkKM) {

                // this.autoApplyPromo()
                // dist = this.$auth.setting.charges[0].delivery_charges
                // Using array length to get the last index value
                const lastChargeIndex = this.getDeliveryZoneSettings.charges.length - 1;
                const lastDeliveryCharge = this.getDeliveryZoneSettings.charges[
                  lastChargeIndex
                ].delivery_charges;
                dist = distancekm == 0.0 ? "0.0" : lastDeliveryCharge;
                this.deliveryCharge = parseFloat(dist);
                // if (this.getDeliveryZoneSettings.is_delivery_charge_tax_enable == 'ON' && this.isPickupDelivery == 1) {
                //   const taxPercentage = this.getDeliveryZoneSettings.delivery_charge_tax;
                //   this.deliveryChargeTax = (this.deliveryCharge * taxPercentage) / 100;
                //   // this.deliveryChargeTax = taxAmount
                // } else {
                //   this.deliveryChargeTax = 0
                // }
              }
              this.deliveryCharge = parseFloat(dist);
            } else {

              this.deliveryCharge = 0;
            }
          });
        } else {
          this.deliveryCharge = 0;
          // this.driver_tip = 0;
        }
      }
    },

    //Grand Total Amount Display
    getTotalAmount() {
      let gstTotalPrice = 0;
      let convenienceCharge = 0;
      let driverTip = 0;
      if (
        this.$auth.setting &&
        (this.$auth.setting.included_tax == 0 || this.$auth.setting.included_tax == "Yes")
      ) {
        gstTotalPrice = parseFloat(this.gstTotalAmount());
      }
      if (
        this.restaurantData.restaurant_convince_type != "" &&
        this.restaurantData.restaurant_convince_charge > 0
      ) {
        convenienceCharge = parseFloat(this.getConvenienceCharge());
      }
      if (this.driver_tip == 0 && this.new_driver_tip > 0) {
        driverTip = parseFloat(this.other_driver_tip);
      } else {
        driverTip = parseFloat(this.driver_tip);
      }

      let totalItemPrice = parseFloat(this.getTotalItemAmount());
      let deliveryTotalPrice = parseFloat(this.deliveryCharge);

      this.others_fees = gstTotalPrice + convenienceCharge + this.packagingCharge + this.getDeliveryTax() + this.adminComissionValue;

      if (this.promoCodeType == 5) {
        let grandTotal =
          totalItemPrice + driverTip + this.others_fees;
        return this.$formatPrice(grandTotal);
        // return grandTotal;
      } else {
        let grandTotal =
          totalItemPrice +
          deliveryTotalPrice +
          driverTip -
          this.promoDiscount +
          this.others_fees;
        return this.$formatPrice(grandTotal);
        //return grandTotal;
      }

      // let grandTotal = (
      //   totalItemPrice +
      //   deliveryTotalPrice +
      //   driverTip -
      //   this.promoDiscount +
      //   this.others_fees
      // ).toFixed(2);
      // return grandTotal;
    },

    //Apply Promocode For function
    applyPromocode(code) {
      // this.promoCashback = 0;
      // this.promoDiscount = 0;
      this.autoApplyCode = "0";
      let currency = "";
      let deliveryType = "Delivery";
      if (this.isPickupDelivery == 1 || this.isPickupDelivery == -1) {
        deliveryType = "Delivery";
      } else {
        deliveryType = "Pickup";
      }
      if (
        document.getElementById("currency") &&
        document.getElementById("currency").childNodes[0]
      ) {
        currency = document.getElementById("currency").childNodes[0].textContent;
      }
      this.promoCashback = 0;
      this.promoDiscount = 0;
      this.promocode = code;
      this.$refs.promocode.value = code;
      if (this.$refs.promocode.value) {
        let clntLatlng = this.primaryAddress;
        this.promocodeRedeem({
          user_id: this.userId,
          vendor_id: this.$auth.getVendorId(),
          is_langauge: this.$store.state.lang.locale,
          original_price: this.getTotalItemAmount(),
          coupon_code: this.$refs.promocode.value,
          restaurant_id: this.restaurantData.restaurant_id,
          coupon_type: deliveryType,
          latitude: clntLatlng.latitude,
          longitude: clntLatlng.longitude,
          coupon_id: this.coupon_id,
          // cityname: localStorage.getItem("city"),
        }).then((data) => {
          if (data.code == 200) {
            let result = data.Result;
            this.autoApplyCode = "0";
            // this.autoApplyCode = result.auto_apply
            this.applyPromoCodeResult = data.Result;
            localStorage.setItem("promocodeType", JSON.stringify(result.promo_code_type));
            if (result.minimum_order_amount <= this.getTotalItemAmount()) {
              this.coupon_id = result.id;
              if (result.promo_code_type == 2) {
                //Falt Discount
                let flatDiscount = result.flat_discount;
                this.promoCodeType = 2;
                if (flatDiscount > this.getTotalItemAmount()) {
                  this.promoDiscount = this.getTotalItemAmount();
                } else {
                  this.promoDiscount = flatDiscount;

                }
                this.promoCashback = 0;
                this.isPromocodeApply = true;

                this.$swal({
                  title: this.$auth.setting.app_name,
                  html:
                    "Promo Code applied successfully. You have got " +
                    '<span class="currency">' +
                    currency +
                    "</span>" +
                    this.promoDiscount +
                    this.$t(" Discount"),
                });
              } else if (result.promo_code_type == 1) {
                //Percenage Discount
                this.promoCodeType = 1;
                let discountAmount = (
                  (this.getTotalItemAmount() / 100) *
                  result.discount_percentage
                ).toFixed(2);
                this.promoDiscount = Math.min.apply(Math, [
                  discountAmount,
                  result.maximum_discount_amount,
                ]);
                this.promoCashback = 0;
                // this.promocode = ""
                this.isPromocodeApply = true;
                this.$swal({
                  title: this.$auth.setting.app_name,
                  html:
                    "Promo Code applied successfully. You have got " +
                    '<span class="currency">' +
                    currency +
                    "</span>" +
                    this.promoDiscount +
                    this.$t(" Discount"),
                });
              } else if (result.promo_code_type == 3) {
                //Flat cashback
                this.promoCodeType = 3;
                let flatCashback = result.flat_discount;
                if (flatCashback > this.getTotalItemAmount()) {
                  this.promoCashback = this.getTotalItemAmount();
                } else {
                  this.promoCashback = flatCashback;
                }
                this.promoDiscount = 0;
                this.isPromocodeApply = true;
                this.$swal({
                  title: this.$auth.setting.app_name,
                  html:
                    "Promo Code applied successfully. You have got " +
                    '<span class="currency">' +
                    currency +
                    "</span>" +
                    this.promoCashback +
                    this.$t(" cashback"),
                });
              } else if (result.promo_code_type == 4) {
                //Percenage Discount
                this.promoCodeType = 4;
                let discountAmount = (
                  (this.getTotalItemAmount() / 100) *
                  result.discount_percentage
                ).toFixed(2);
                this.promoCashback = Math.min.apply(Math, [
                  discountAmount,
                  result.maximum_discount_amount,
                ]);
                this.promoDiscount = 0;
                this.isPromocodeApply = true;
                this.$swal({
                  title: this.$auth.setting.app_name,
                  html:
                    "Promo Code applied successfully. You have got " +
                    '<span class="currency">' +
                    currency +
                    "</span>" +
                    this.promoCashback +
                    this.$t(" cashback"),
                });
              } else if (result.promo_code_type == 5) {
                //Percenage Discount
                this.promoDiscount = this.deliveryCharge;
                // this.deliveryCharge = 'free'
                this.autoApplyCode = "0";
                this.promoCodeType = 5;
                this.isPromocodeApply = true;
                this.$swal({
                  title: this.$auth.setting.app_name,
                  html:
                    "Promo Code applied successfully. You have got " +
                    '<span class="currency">' +
                    currency +
                    "</span>" +
                    this.deliveryCharge +
                    this.$t(" Discount"),
                });
              }
            } else {
              this.$swal({
                title: this.$auth.setting.app_name,
                html:
                  "Sorry, the minimum order amount is  " +
                  '<span class="currency">' +
                  currency +
                  "</span>" +
                  result.minimum_order_amount,
              });
            }
          } else if (data.code == 101) {
            this.promoError = data.msg;
            setTimeout(() => (this.promoError = false), 3000);
          }
        });
      } else {
        this.promoError = this.$t("Please Enter Promocode");
        setTimeout(() => (this.promoError = false), 2000);
      }
      $("#promocodeModal").modal("hide");
    },

    autoApplyPromo() {
      // this.autoApplyCode = "0"
      this.latitude = localStorage.getItem("latitude");
      this.longitude = localStorage.getItem("longitude");
      let deliveryType = "Delivery";
      if (this.isPickupDelivery == 1 || this.isPickupDelivery == -1) {
        deliveryType = "Delivery";
      } else {
        deliveryType = "Pickup";
      }
      this.autoApplyCouponRedeem({
        vendor_id: this.$auth.getVendorId(),
        is_langauge: this.$store.state.lang.locale,
        original_price: this.getTotalItemAmount(),
        user_id: this.$auth.user.user_id,
        restaurant_id: this.cart[0].restaurant_id,
        coupon_type: deliveryType,
        latitude: this.latitude,
        longitude: this.longitude,
      }).then((data) => {
        if (data.code == 200) {
          let currency = "";
          if (
            document.getElementById("currency") &&
            document.getElementById("currency").childNodes[0]
          ) {
            currency = document.getElementById("currency").childNodes[0].textContent;
          }
          this.promocode = data.Result.coupon_code;
          let result = data.Result;
          this.autoApplyCode = result.auto_apply;
          // this.autoApplyCode = "0"
          this.coupon_id = result.id;
          localStorage.setItem("promocode", JSON.stringify(result));
          if (result.promo_code_type == 1) {
            this.promoCodeType = 1;
            let discountAmount = (
              (this.getTotalItemAmount() / 100) *
              result.discount_percentage
            ).toFixed(2);
            this.promoDiscount = Math.min.apply(Math, [
              discountAmount,
              result.maximum_discount_amount,
            ]);

            this.promoCashback = 0;
            // this.promocode = ""
            this.isPromocodeApply = true;
            this.$swal({
              title: this.$auth.setting.app_name,
              html:
                "Promo Code applied successfully. You have got " +
                '<span class="currency">' +
                currency +
                "</span>" +
                this.promoDiscount +
                this.$t(" Discount"),
            });
          } else if (result.promo_code_type == 2) {
            let flatDiscount = result.flat_discount;
            this.promoCodeType = 2;
            if (flatDiscount > this.getTotalItemAmount()) {
              this.promoDiscount = this.getTotalItemAmount();
            } else {
              this.promoDiscount = flatDiscount;
            }
            this.promoCashback = 0;
            this.isPromocodeApply = true;
            this.$swal({
              title: this.$auth.setting.app_name,
              html:
                "Promo Code applied successfully. You have got " +
                '<span class="currency">' +
                currency +
                "</span>" +
                this.promoDiscount +
                this.$t(" Discount"),
            });
          } else if (result.promo_code_type == 3) {
            this.promoCodeType = 3;
            localStorage.removeItem("deliveryCharges");
            let flatCashback = result.flat_discount;
            if (flatCashback > this.getTotalItemAmount()) {
              this.promoCashback = this.getTotalItemAmount();
              this.promoDiscount = 0;
            } else {
              this.promoCashback = flatCashback;
              this.promoDiscount = 0;
            }
            this.promoDiscount = 0;
            this.isPromocodeApply = true;
            this.$swal({
              title: this.$auth.setting.app_name,
              html:
                "Promo Code applied successfully. You have got " +
                '<span class="currency">' +
                currency +
                "</span>" +
                this.promoCashback +
                this.$t(" Cashback"),
            });
          } else if (result.promo_code_type == 4) {
            this.promoCodeType = 4;
            let discountAmount = (
              (this.getTotalItemAmount() / 100) *
              result.discount_percentage
            ).toFixed(2);
            this.promoCashback = Math.min.apply(Math, [
              discountAmount,
              result.maximum_discount_amount,
            ]);
            this.promoDiscount = 0;
            this.isPromocodeApply = true;
            this.$swal({
              title: this.$auth.setting.app_name,
              html:
                "Promo Code applied successfully. You have got " +
                '<span class="currency">' +
                currency +
                "</span>" +
                this.promoCashback +
                this.$t(" Cashback"),
            });
          } else if (result.promo_code_type == 5) {
            //Percenage Discount
            // this.promoCodeType = 5;
            // let freeDelivery = localStorage.getItem('deliveryCharges')
            // this.promoDiscount = freeDelivery;
            this.promoCodeType = 5;
            this.isPromocodeApply = true;
            if (this.isPopup) {
              this.$swal({
                title: this.$auth.setting.app_name,
                html:
                  "Promo Code applied successfully. You have got " +
                  '<span class="currency">' +
                  currency +
                  "</span>" +
                  this.$t("Free"),
              });
            }
            this.isPopup = true;
          }
        } else if (data.code == 204) {
          this.promoSuggetion = data.msg;
          this.isPromocodeApply = false;
          // setTimeout(() => (this.promoError = false), 3000);
        }
      });
    },

    //Remove Apply Code
    removePromocode() {
      localStorage.removeItem("place_order_data");
      localStorage.removeItem("promocodeType");
      this.promocode = "";
      this.promoSuggetion = "";
      this.isPromocodeApply = false;
      this.promoDiscount = 0;
      this.promoCashback = 0;
      this.promoCodeType = 0;
    },

    comapreDate(d1, d2) {
      let dateTime1 = new Date(d1);
      let dateTime2 = new Date(d2);

      let time1 =
        dateTime1.getHours() * 60 * 60 * 1000 +
        dateTime1.getMinutes() * 60 * 1000 +
        dateTime1.getSeconds() * 1000 +
        dateTime1.getMilliseconds();
      let time2 =
        dateTime2.getHours() * 60 * 60 * 1000 +
        dateTime2.getMinutes() * 60 * 1000 +
        dateTime2.getSeconds() * 1000 +
        dateTime2.getMilliseconds();
      if (time1 < time2) {
        return true;
      } else {
        return false;
      }
    },


    checkEmailBeforeProceed() {
      // Check if the user's email is null
      if (this.$auth.user.user_email == null) {
        // Show SweetAlert modal asking for an email address
        this.$swal({
          title: 'Add Your Email Address',
          html: "Please provide your email address. This will help us keep you informed about your order status, delivery details, and any special offers.",
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            // Redirect to profile page if Yes is clicked
            this.$router.push({ name: 'profile' });
          } else if (result.dismiss == this.$swal.DismissReason.cancel) {
            // No action needed when canceled, but it should prevent proceeding
          }
        });
        return false; // Prevents further execution
      }
      return true; // Email exists, allow further execution
    },

    async processToPay() {
      //if email not exist then redirect for profile page
      if (this.$auth.user && this.$auth.user.user_email == null && !this.emailPromptDismissed) {
        this.$swal({
          title: 'Add Your Email Address',
          html: "Please provide your email address. This will help us keep you informed about your order status, delivery details, and any special offers.",
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            // Redirect to profile page if Yes is clicked
            this.$router.push({ name: 'profile' });
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.emailPromptDismissed = true; // Set the flag when No is clicked
            // this.$router.push({ name: 'payment' });
          }
        });
        return true;
      }


      // Business Operation Hours check a time  start time and end time == start
      const currentYear = new Date().getFullYear();
      const systemStartYear = new Date(
        this.$auth.setting.system_start_time
      ).getFullYear();
      const systemEndYear = new Date(this.$auth.setting.system_end_time).getFullYear();

      if (!(currentYear >= systemStartYear && currentYear <= systemEndYear)) {
        // Display a message or perform an action if the current year is outside the range
        this.$swal({
          title: this.$auth.setting.app_name,
          html: "The Restaurants has closed now.<br/>Please come back later",
        });
        return true;
      }

      // if (!(this.comapreDate(this.$auth.setting.system_start_time, this.getCurrentDate) && this.comapreDate(this.getCurrentDate, this.$auth.setting.system_end_time))) {
      //   this.$swal({
      //     title: this.$auth.setting.app_name,
      //     html: 'The Restaurants has closed now.<br/>Please come back later',
      //   });
      //   return true;
      // }

      // if ((this.comapreDate(this.getCurrentDate, this.$auth.setting.system_end_time))) {
      //   this.$swal({
      //     title: this.$auth.setting.app_name,
      //     html: 'The restaurant has closed now.<br/>Please come back later',
      //   });
      //   return true;
      // }

      // if (this.comapreDate(this.$auth.setting.system_start_time,) && this.comapreDate(this.getCurrentDate, this.$auth.setting.system_end_time)) {
      //   this.$swal({
      //     title: this.$auth.setting.app_name,
      //     html: 'The Restaurants has closed now.<br/>Please come back later 111',
      //   });
      //   return false
      // }
      // Business Operation Hours check a time  start time and end time  == end

      if (
        this.getDeliveryZoneSettings.is_delivery_zone_available == null ||
        this.getDeliveryZoneSettings.is_delivery_zone_available == "No"
      ) {
        this.$swal({
          title: this.$auth.setting.app_name,
          html: this.getDeliveryZoneSettings.not_available_delivery_zone_reason,
        });
        return true;
      }


      if (this.$auth.setting.is_active_porter == "Yes" && this.$auth.setting.porter_delivery_charges == "Yes" && this.seletectedPorter == 0 && this.isPickupDelivery == 1) {
        this.$toasted.error(this.$t("Please Select Your Delivery Option"), {
          duration: 2000,
        });
        return false;
      }

      let response = await this.getAllDeliveryZoneSeeting(this.latitude, this.longitude);

      if (response == undefined || response) {
        this.$swal({
          title: this.$auth.setting.app_name,
          html:
            "Sorry, At this moment we are not providing delivery service at your selected location.<br> <b>Please change address</b>",
        });
        return false;
      }

      if (this.isPickupDelivery == 1 || this.isPickupDelivery == -1) {
        if (this.shiping_id == "") {
          this.$toasted.error(this.$t("Please select or add address"), {
            duration: 2000,
          });
          return false;
        }
      }


      // if takeaway option admin site off this showing
      // if (this.$auth.setting.is_pickup_enable == 'false') {
      //   this.$swal({
      //     title: this.$auth.setting.app_name,
      //     html: "Can't delivery to  your location.Sorry Takeaway option close now.",
      //   });
      //   return false;
      // }

      this.is_btn_spinner = true;

      if (!this.isAsapEnable || !this.isRestaurantClosed || !this.isSameDayDelivery || this.restaurantData.is_pre_order == '1') {
        if (
          !this.scheduleDateTime.time ||
          this.scheduleDateTime.time == "" ||
          this.scheduleDateTime.time == "asap"
        ) {
          this.$refs.DeliveryTime.openTime();
          this.is_btn_spinner = false;
          return false;
        }
      }

      if (this.$auth.user) {
        let itemDetailsArray = [];
        let itemDetails = {};

        this.cart.forEach((item) => {
          let singleSelection = "";
          let multiple_selection = "";
          multiple_selection = item.selectCustoizeID.join();
          itemDetails = {
            item_id: item.item_id,
            amount: parseFloat(this.getTotalItemPrice(item)).toFixed(2),
            single_selection: singleSelection,
            is_bravges: "0",
            multiple_selection: multiple_selection,
            quantity: item.quantity,
          };
          itemDetailsArray.push(itemDetails);
        });

        let future_delivery_date = "";

        if (this.scheduleDateTime) {
          if (this.scheduleDateTime.time && this.scheduleDateTime.time != "asap") {
            //Schedule order
            future_delivery_date =
              this.scheduleDateTime.date + " " + this.scheduleDateTime.time;
          }
        }

        let deliveryType = "Delivery";

        if (this.isPickupDelivery == 1 || this.isPickupDelivery == -1) {
          deliveryType = "Delivery";
        } else {
          deliveryType = "Pickup";
        }

        let promocodeType = JSON.parse(localStorage.getItem("promocodeType"));
        this.place_order_parameter = {
          address_id: this.shiping_id,
          app_version_code: "",
          braveges_amount: 0,
          card_id: "",
          cashback: this.promoCashback,
          promo_code_type: promocodeType,
          CGST: "0.0",
          contact_less_delivery: this.contactDelivery,
          coupon_id: this.coupon_id,
          coupon_redeem: this.coupon_id == "" ? "" : this.promocode,
          delivery_fee: this.deliveryCharge,
          delivery_pickup_types: deliveryType,
          delivery_type: this.isPickupDelivery,
          device_type: "Web",
          discount_price: this.getTotalAmount(), //this.promoDiscount,
          driver_tip: this.new_driver_tip > 0 ? this.other_driver_tip : this.driver_tip,
          future_delivery_date: future_delivery_date,
          is_langauge: this.$store.state.lang.locale,
          notes: this.instructions,
          original_price:
            parseFloat(this.getTotalItemAmount()) +
            parseFloat(this.deliveryCharge) +
            parseFloat(this.gstTotalAmount()) +
            parseFloat(this.packagingCharge.toFixed(2)) +
            parseFloat(this.convince_charge) +
            parseFloat(this.getDeliveryTax()) +
            parseFloat(this.getShortFees()) +
            parseFloat(this.new_driver_tip > 0 ? this.other_driver_tip : this.driver_tip), //this.getTotalAmount(),
          payment_method: "",
          payment_type: "",
          remaining_amount: this.getTotalAmount(),
          restaurant_id: this.restaurantData.restaurant_id,
          restaurant_item_details: JSON.stringify(itemDetailsArray),
          // restaurant_service_fee: this.gstMaxtax,
          service_fee: this.gstTotalAmount(),
          SGST: 0.0,
          sub_total: this.getTotalItemAmount(),
          suggestion: "",
          total_amount: this.getTotalAmount(),
          transaction_id: "",
          user_id: this.userId,
          vendor_id: this.$auth.getVendorId(),
          wallet_amount: 0.0,
          orderImagesid: this.orderImagesId,
          saved_amount: parseFloat(this.savedPrice()) + parseFloat(this.promoDiscount),
          item_tax: this.itemTexDisplay(),
          packaging_charge: parseFloat(this.packagingCharge).toFixed(2),
          convince_charge: this.convince_charge,
          userPrimaryAddress: this.primaryAddress,
          promoDiscount: this.promoDiscount,
          is_other_Tip: this.is_other_Tip,
          autoApplyCode: this.autoApplyCode,
          delivery_instruction: JSON.stringify(this.Instruction),
          time_slot: this.restaurantData && this.restaurantData.time_slots && this.restaurantData.time_slots != "" ? this.restaurantData.time_slots : 0,
          delivery_charges_tax: this.getDeliveryTax().toFixed(2),
          delivery_charges_tax_percentage: this.getDeliveryZoneSettings.delivery_charge_tax,
          short_order_fee: this.getShortFees().toFixed(2)
          // eta: this.$auth.setting.is_real_time_eta_enable == 1 ? this.restaurantData.delivery_time : ''
        };



        if (this.isPickupDelivery == 1 || this.isPickupDelivery == -1) {
          await this.checkRestaurantRadius();
        } else {
          await this.checkRestaurantMenuWithPrice();
        }
      } else {
        this.$router.push({ name: "sign-in" });
      }
    },
    checkRestaurantRadius() {
      let vm = this;
      this.checkVendorRadius({
        is_langauge: this.$store.state.lang.locale,
        latitude:
          this.primaryAddress.latitude && this.primaryAddress.latitude == ""
            ? this.primaryAddress.latitude
            : parseFloat(localStorage.getItem("latitude")),
        longitude:
          this.primaryAddress.longitude && this.primaryAddress.longitude == ""
            ? this.primaryAddress.longitude
            : parseFloat(localStorage.getItem("longitude")),
        restaurant_id: this.restaurantId,
        vendor_id: this.$auth.getVendorId(),
      })
        .then((data) => {
          if (data.code == 200) {
            this.checkRestaurantMenuWithPrice();
          } else if (this.restaurantDetails.restaurant_on_off == null) {
            this.$swal({ title: this.$t("This Restaurant is closed") });
            this.is_btn_spinner = false;
            return false;
          } else {
            this.$swal({
              title: this.$t("Oops! We do not deliver at your selected location "),
            });
            this.is_btn_spinner = false;
            return false;
          }
        })
        .catch(function () {
          vm.$swal({ title: vm.$t("Something went wrong, Please try again") });
          vm.is_btn_spinner = false;
          return false;
        });
    },

    checkRestaurantMenuWithPrice() {
      let menu_item_ids = [];
      let menu_item_price = [];
      let customisation_menu_item_ids = [];
      let customisation_menu_item_price = [];
      let menu_item_quantity = [];
      let item_packaging_charge = [];

      this.cart.forEach((item) => {
        menu_item_ids.push(item.item_id);
        menu_item_price.push(item.item_price);
        menu_item_quantity.push(item.quantity);
        item_packaging_charge.push(item.packaging_charge);
        if (item.selected_type.length) {
          customisation_menu_item_ids.push(item.selectCustoizeID);
          customisation_menu_item_price.push(item.selectCustoizePrice);
        }
      });
      let postdata = {
        is_langauge: this.$store.state.lang.locale,
        vendor_id: this.$auth.getVendorId(),
        restaurant_id: this.restaurantId,
        menu_item: menu_item_ids.join(),
        item_price: menu_item_price.join(),
        customisation_id: customisation_menu_item_ids.join(),
        customisation_price: customisation_menu_item_price.join(),
        item_quantity: menu_item_quantity.join(),
        beverage_ids: "",
        packaging_charge: item_packaging_charge.join(),
      };
      this.checkVendorMenuWithPrice(postdata).then((data) => {
        if (data.code == 200) {
          this.is_btn_spinner = false;
          this.$store.dispatch("order/placeOrderData", this.place_order_parameter);
          localStorage.setItem(
            "place_order_data",
            JSON.stringify(this.place_order_parameter)
          );
          this.$refs.redirectPayment.$el.click();
        } else if (data.code == 205) {
          this.is_btn_spinner = false;
          data.Result.forEach((updateData) => {
            const cartindex = this.cart.findIndex(
              (item) => parseInt(item.item_id) === parseInt(updateData.item_id)
            );
            let itemTax = 0;
            if (updateData.quantity == 0) {
              this.cart.splice(cartindex, 1);
            } else {
              if (updateData.item_tax != "0") {
                itemTax =
                  updateData.price - updateData.price / (1 + updateData.item_tax / 100);
              }
              this.cart[cartindex].item_tax = itemTax;
              this.cart[cartindex].item_price = updateData.price;
              this.cart[cartindex].mrp = updateData.mrp;
              this.cart[cartindex].packaging_charge = parseFloat(
                updateData.packaging_charge
              );
              if (updateData.quantity < this.cart[cartindex].quantity) {
                this.cart[cartindex].quantity = updateData.quantity;
                this.cart[cartindex].itemTotalQuantity = updateData.quantity;
              }
            }
          });
          /****Customization****/
          if (data.customisation.length > 0) {
            this.cart.forEach((item, i) => {
              data.customisation.forEach((customis) => {
                const cartcustindex = item.selectCustoizeID.findIndex(
                  (itmcust) => parseInt(itmcust) === parseInt(customis.customisation_id)
                );
                this.cart[i].selectCustoizePrice[cartcustindex] = customis.price;
              });
            });
          }
          this.$store.commit("product/customizeCartUpdate", true);
          this.$toasted.success(this.$t("Cart updated"), { duration: 3000 });
        } else {
          this.is_btn_spinner = false;
          alert(data.msg);
        }
      });
    },

    goToHome() {
      this.$router.go(-1);
    },

    //Order Image Upload
    uploadImage(e) {
      let vm = this;
      var selectedFiles = e.target.files;
      let fileSize = selectedFiles[0].size / 1024 / 1024;
      if (fileSize <= 1) {
        var formData = new FormData();
        var totalImg = selectedFiles.length + this.images.length;
        if (totalImg > this.$auth.setting.order_image_upload_limit) {
          this.$toasted.error(
            this.$t(
              "You can add maximum " +
              this.$auth.setting.order_image_upload_limit +
              " photos"
            ),
            { duration: 2000 }
          );
        } else {
          this.loading = true;
          this.is_btn_spinner = true;
          for (let i = 0; i < selectedFiles.length; i++) {
            formData.append("image[" + i + "]", selectedFiles[i]);
          }
          this.postUploadOrderImages(formData).then((data) => {
            if (data.code == 200) {
              let imagesArray = data.imagedata;
              if (
                localStorage.getItem("orderUploadImages") != "" ||
                localStorage.getItem("orderUploadImages") != null
              ) {
                imagesArray.forEach((imgdata) => {
                  this.images.push(imgdata);
                });
              } else {
                this.images = imagesArray;
              }
              localStorage.setItem("orderUploadImages", JSON.stringify(this.images));
              let ordImg = [];
              this.images.forEach((imgid) => {
                ordImg.push(imgid.id);
              });
              this.orderImagesId = ordImg.join(",");
              this.loading = false;
              this.is_btn_spinner = false;
            } else {
              this.is_btn_spinner = false;
              this.loading = false;
            }
          });
        }
      } else {
        this.$toasted.error(this.$t("File too Big, please select a file less than 1mb"), {
          duration: 2000,
        });
      }
    },

    //Remove uplod image from
    removeUploadImage(id) {
      this.$swal({
        title: this.$t("Are you sure you want to delete?"),
        type: "warning",
        customClass: {
          confirmButton: "cofirm-btn-new",
          cancelButton: "cancle-btn-new",
          title: "clear-cart-text mt-3 mb-1",
        },
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: this.$t("Yes"),
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          this.is_btn_spinner = true;
          this.postremoveOrderImages({
            imageid: id,
          }).then((data) => {
            if (data.code == 200) {
              localStorage.setItem("orderUploadImages", "");
              let ordImg = [];
              this.orderImagesId = "";
              var rIndex = this.images.findIndex((r) => r.id == id);
              this.images.splice(rIndex, 1);
              this.images.forEach((imgid) => {
                ordImg.push(imgid.id);
              });
              localStorage.setItem("orderUploadImages", JSON.stringify(this.images));
              this.orderImagesId = ordImg.join(",");
              this.loading = false;
              this.is_btn_spinner = false;
            }
          });
        }
      });
    },

    /**
     * Cart clear for function.
     */
    cleanCart() {
      this.$swal({
        title: this.$t("Are you sure you want to clear cart?"),
        type: "warning",
        customClass: {
          confirmButton: "cofirm-btn-new",
          cancelButton: "cancle-btn-new",
          title: "clear-cart-text mt-3 mb-1",
        },
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: this.$t("Yes"),
      }).then((result) => {
        // <--
        if (result.isConfirmed) {
          // <-- if confirmed
          localStorage.setItem("driverTip", (this.driver_tip = 0));
          this.$store.commit("product/clearCartItems");
          localStorage.removeItem("place_order_data");
        }
      });
    },

    readMore(value) {
      $("#moredesc" + value).toggle(400);
      $("#shortdec" + value).toggle(400);
    },

    /**
     * For promocode Apply
     */
    applyPromocodeByItem() {
      // this.applyPromoCodeResult = {};
      let currency = "";
      if (
        document.getElementById("currency") &&
        document.getElementById("currency").childNodes[0]
      ) {
        currency = document.getElementById("currency").childNodes[0].textContent;
      }
      if (this.applyPromoCodeResult.minimum_order_amount <= this.getTotalItemAmount()) {
        if (this.applyPromoCodeResult.promo_code_type == 2) {
          //Falt Discount
          let flatDiscount = this.applyPromoCodeResult.flat_discount;
          this.promoCodeType = 2;
          if (flatDiscount > this.getTotalItemAmount()) {
            this.promoDiscount = this.getTotalItemAmount();
          } else {
            // this.promocode = ""
            this.promoDiscount = flatDiscount;
          }
          this.isPromocodeApply = true;
        } else if (this.applyPromoCodeResult.promo_code_type == 1) {
          //Percenage Discount
          this.promoCodeType = 1;
          let discountAmount = (
            (this.getTotalItemAmount() / 100) *
            this.applyPromoCodeResult.discount_percentage
          ).toFixed(2);
          // this.promocode = ""
          this.promoDiscount = Math.min.apply(Math, [
            discountAmount,
            this.applyPromoCodeResult.maximum_discount_amount,
          ]);
          this.isPromocodeApply = true;
        } else if (this.applyPromoCodeResult.promo_code_type == 3) {
          //Flat cashback
          this.promoCodeType = 3;
          let flatCashback = this.applyPromoCodeResult.flat_discount;
          if (flatCashback > this.getTotalItemAmount()) {
            this.promoCashback = this.getTotalItemAmount();
            // this.promoDiscount = 0;
            this.applyPromoCodeResult = {};
          } else {
            // this.promocode = ""
            // this.promoDiscount = 0;
            this.promoCashback = flatCashback;
          }
          this.isPromocodeApply = true;
        } else if (this.applyPromoCodeResult.promo_code_type == 4) {
          //Percenage Discount
          this.promoCodeType = 4;
          let discountAmount = (
            (this.getTotalItemAmount() / 100) *
            this.applyPromoCodeResult.discount_percentage
          ).toFixed(2);
          this.promoCashback = Math.min.apply(Math, [
            discountAmount,
            this.applyPromoCodeResult.maximum_discount_amount,
          ]);
          this.applyPromoCodeResult = {};
          this.isPromocodeApply = true;
        } else if (this.applyPromoCodeResult.promo_code_type == 5) {
          // deliveryCharge = this.getDeliveryZoneSettings.charges
          //   deliveryCharge.forEach((charge) => {
          //   // this.driver_tip = 0
          //   if (distancekm > charge.min_value && distancekm <= charge.max_value) {
          //     dist = charge.delivery_charges;
          //     checkKM = true;
          //   }
          // })
          // Percenage Discount
          this.promoCodeType = 5;
          // this.promocode = ""
          // this.promoDiscount = this.deliveryCharge;
          this.promoDiscount = 0;
          this.isPromocodeApply = true;
        }
      } else {
        this.applyPromoCodeResult = {};
        this.promocode = "";
        this.promoCodeType = 0;
        this.isPromocodeApply = false;
        this.promoDiscount = 0;
        this.promoCashback = 0;
        // this.$swal({
        //   title:this.restaurantData.name,
        //   html:'Sorry, the minimum order amount is  '+ '<span class="currency">'+currency+'</span>' +this.applyPromoCodeResult.minimum_order_amount,
        // })
      }
    },

    getDelivery(value) {
      if (localStorage.getItem("pickupDeliveryFlag") == value) {
        return false;
      }
      if (value == 0 || value == "0") {
        this.deliveryCharge = 0;
        this.deliveryChargeTax = 0
      }
      this.removePromocode();
      this.$store.commit("order/pickupdelivery", value);
      localStorage.setItem("pickupDeliveryFlag", value);
      this.getDeliveryChrge();
    },

    floatNumberOnly(evt) {
      let val1 = evt.target.value;
      if (!(evt.keyCode == 46 || (evt.keyCode >= 48 && evt.keyCode <= 57))) {
        evt.preventDefault();
      }
      let partlen = val1.split(".").length - 1;
      if (evt.keyCode == 46 && partlen == 1) {
        evt.preventDefault();
      }
    },

    tipModal() {
      if (this.driver_tip > 0) {
        this.driver_tip = 0;
      }
      $("#driverTipModal").modal("show");
    },

    otherFeesModal() {
      $("#otherFeesModal").modal("show");
    },
    // portalModalOpen() {
    //   $("#portalModal").modal("show");
    // },

    closeTexModal() {
      $("#otherFeesModal").modal("hide");
      $("#portalModal").modal("hide");
    },

    closeTipModal(id) {
      // this.other_driver_tip = document.getElementById('tripValue').value;
      if (this.other_driver_tip > 0) {
        this.is_other_Tip = true;
        this.new_driver_tip = this.other_driver_tip;
        $("#tip_other").prop("checked", true);
        $("#tip_other").addClass("active");
        $("#driverTipModal").modal("hide");
        this.radioCounts = 1;
        // localStorage.setItem("new_driver_tip", JSON.stringify({ id: "tip_other", value: this.new_driver_tip }));
      } else {
        this.$toasted.error(this.$t("Please enter proper amount"), {
          duration: 3000,
        });
      }
    },

    // getPorterValue(index, value) {
    //   if (this.seletectedPorter === index) {
    //     this.seletectedPorter = 0;
    //   } else {
    //     this.seletectedPorter = { index, value };
    //     this.seletectedPorterIndex = index;
    //     this.deliveryCharge = this.seletectedPorterIndex / 100;
    //     // $("#portalModal").modal("hide");
    //   }
    //   const chargeIndex = this.porterCharges.indexOf(index);
    //   if (chargeIndex !== -1) {
    //     this.porterCharges.splice(chargeIndex, 1);
    //   }
    //   // $("#tip_" + index).prop("checked", this.seletectedPorter !== index);


    //   // this.seletectedPorter = index; // Capture the selected index
    //   // // let porterCharges = []
    //   // var index = this.porterCharges.indexOf(index);
    //   // if (index !== -1) {
    //   //   this.porterCharges.splice(index, 1);
    //   // }
    //   // $("#tip_" + index).prop("checked", false);
    //   // this.seletectedPorter = 0;
    // },


    getPorterValue(value, index) {
      // Toggle logic for active index
      // if (this.activePorterIndex === index) {
      //   this.activePorterIndex = null; // Deselect if already selected
      //   this.seletectedPorter = null;
      //   this.deliveryCharge = 0
      // } else {
      this.activePorterIndex = index; // Set the current index as active
      this.seletectedPorter = value;

      this.seletectedPorterIndex = index;
      this.deliveryCharge = value / 100; // Update delivery charge
      // }
    },

    getTipValue(value, type, id) {
      if (type == "other_driver_tip") {
        this.tipsClick = [];
        if (this.radioCounts == 1) {
          $("#tip_other").prop("checked", false);
          this.new_driver_tip = 0;
          this.radioCounts = 0;
        } else {
          $("#tip_other").prop("checked", true);
          this.tipsClick.push(id);
          this.new_driver_tip = value;
          this.radioCounts = 1;
        }
        this.driver_tip = 0;
      } else {
        // this.other_driver_tip = '';
        this.radioCounts = 0;
        if (this.tipsClick.includes(id)) {
          var index = this.tipsClick.indexOf(id);
          if (index !== -1) {
            this.tipsClick.splice(index, 1);
          }
          $("#tip_" + id).prop("checked", false);
          this.driver_tip = 0;
        } else {
          this.is_other_Tip = false;
          this.tipsClick = [];
          this.tipsClick.push(id);
          this.driver_tip = value;
        }
        this.new_driver_tip = 0;
      }
    },


    // get delivery instruction checked this function
    getDeliveryInstruction(value, type, id) {
      const index = this.Instruction.findIndex(instruction => instruction.id === id);
      if (index !== -1) {
        this.Instruction.splice(index, 1);
        this.$set(this.isInstruction, id, false);
      } else {
        this.Instruction.push({ id: id, value: value });
        this.$set(this.isInstruction, id, true);
      }
      //localStorage.setItem('selectedInstruction', JSON.stringify(this.Instruction));
    },

    cancelTipModal(val) {
      // localStorage.removeItem("new_driver_tip")
      // document.getElementById('tripValue').value = null;
      $("#driverTipModal").modal("hide");
      this.other_driver_tip = val;
      if (this.other_driver_tip == 0) {
        this.is_other_Tip = false;
        this.new_driver_tip = 0;
        // this.other_driver_tip = "";
        $("#tip_other").prop("checked", false);
        $("#tip_other").removeClass("active");
      } else {
        this.new_driver_tip = this.other_driver_tip;
        $("#tip_other").prop("checked", true);
      }
    },
  },
};
</script>

<style scoped>
.svprc {
  padding: 0 15px;
  background: rgba(var(--theme-primary));
  margin: 0 -15px;
  color: #fff;
}

.svprc p {
  padding: 7px;
}

.delivery-address {
  padding: 15px;
  position: sticky;
}

.delivery-address .delivery-pickup-main {
  display: inline-flex !important;
}

.delivery-address .delivery-nw-section {
  display: contents;
}

.delivery-nw-section span {
  margin-right: 30px;
  font-weight: 500;
}

.delivery-nw-section .chng-addrs {
  margin-left: 24px;
}

.pckup-addr-cls .pickup-txt {
  margin-right: 32%;
  font-weight: 500;
}

button.btn.btn-primary.disabled,
.prcc-cls-blr,
.prcc-cls,
.prcc-cls:hover,
.prcc-cls-blr:hover {
  background: #27ae60;
  border: none;
}

.delivery-address p {
  white-space: unset;
  width: 100%;
  overflow: unset;
}

@media only screen and (max-width: 767px) {
  .delivery-address p {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }

  .pckup-addr-cls .pickup-txt {
    margin-right: 12%;
  }

  .delivery-pickup-main .delivery-pickup-lnk {
    width: 146px;
  }

  .delivery-nw-section span {
    font-size: 12px;
    margin-right: 0;
  }

  .delivery-nw-section .chng-addrs {
    margin-left: 1px;
    font-size: 12px;
  }
}

.options-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-top: 20px;
  cursor: pointer;
}

.card-title {
  font-size: 12px;
}

.option-card {
  border: 1px solid var(--secondary-gray);
  border-radius: 8px;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;
}



.option-card img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.option-card h5 {
  font-size: 12px;
}

.option-card p {
  margin: 0 0;
  font-size: 12px;
  color: #555;
}



button {
  margin-top: 10px;
  padding: 10px 15px;
  font-size: 12px;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.vehicle-name {
  padding: 0;
}

button:hover {
  background: #0056b3;
}

.option-card.card.active-border {
  border-color: rgba(var(--theme-primary));
  color: var(--theme-font-primary);
  /* Active border color */
}

.cust-porter-btn.active-border {
  border-color: rgba(var(--theme-primary));
  color: rgba(var(--theme-primary));
}

.cust-porter-btn.active-border p {
  border-color: rgba(var(--theme-primary));
  color: rgba(var(--theme-primary));
}

@media (max-width: 600px) {
  .options-grid {
    grid-template-columns: 1fr;
  }
}
</style>
